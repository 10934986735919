define('m01new/models/dachgeometrie', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var dachgeometrie = DS['default'].Model.extend({

    konstruktionID: DS['default'].attr('string'),
    DachFormID: DS['default'].attr('string'),
    ALFA_Grad: DS['default'].attr('string'),
    DN_Walm: DS['default'].attr('string'),
    DN: DS['default'].attr('string'),
    DN_Grad: DS['default'].attr('string'),
    LTraufe: DS['default'].attr('string'),
    L_Sp: DS['default'].attr('string'),
    LFirst: DS['default'].attr('string'),
    A_Dach: DS['default'].attr('string'),
    Ue_Giebel: DS['default'].attr('string'),
    Ue_Traufe: DS['default'].attr('string'),
    Ue_First: DS['default'].attr('string'),
    hFirst: DS['default'].attr('string'),
    ueberdaemmteFlaeche: DS['default'].attr('string'),
    berechneteUeberdaemmteFlaeche: DS['default'].attr('string'),
    gebaeudeBreite: DS['default'].attr('string'),
    gebaeudeLaenge: DS['default'].attr('string'),
    MassB: DS['default'].attr('string'),
    eDachlatten: DS['default'].attr('string'),
    LOrtgang: DS['default'].attr('string')

  });

  dachgeometrie.reopenClass({
    FIXTURES: [{
      id: 1,
      konstruktionID: "0",
      DachFormID: "0",
      ALFA_Grad: "30",
      DN_Walm: "40",
      DN: "30",
      DN_Grad: "30",
      LTraufe: "20",
      L_Sp: "20",
      LFirst: "0",
      A_Dach: "1143000",
      Ue_Giebel: "0.5",
      Ue_Traufe: "0.5",
      Ue_First: "0.5",
      hFirst: "8",
      ueberdaemmteFlaeche: "1143000",
      berechneteUeberdaemmteFlaeche: -1,
      gebaeudeBreite: "800",
      gebaeudeLaenge: "1000",
      MassB: "25",
      eDachlatten: "0.35",
      LOrtgang: "0.0"

    }]
  });

  exports['default'] = dachgeometrie;

});