define('m01new/routes/lasteinwirkungsi', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    i18n: Ember['default'].inject.service(),

    model: function model() {
      return this.store.find('lasteinwirkung');
    },

    setupController: function setupController() {
      this.controllerFor('application').miniertX3D();
      this.controllerFor('application').set('currentPath', 'lasteinwirkung');
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('istDachkonstruktion', true);
      this.controllerFor('lasteinwirkung').lastenAbfragen();
    }

  });

});