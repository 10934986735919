define('m01new/controllers/lasteinwirkunguk', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    displayErrors: true,
    loading: false,

    istFassade: false,

    triggerWatchStandortID: true,
    triggerWatchComboboxes: true,
    triggerWatchGeodaten: true,

    beschreibungAktiv: false,
    gAktiv: false,
    skAktiv: false,
    gelaendehoeheAktiv: false,
    qAktiv: false,

    entfKuesteAktiv: false,

    entfKueste: "0.0",
    entfKuesteDisabeld: false,

    gelaenderauhigkeitsklasseDisabeld: false,

    bmlast: "",
    verbindungsmittelliste: "",
    ergebnisschraube: [],

    slz: 1,
    wlz: 1,
    dacheindeckung: 4,
    gelaendehoehe: "0",
    geoDatenManuell: false,
    geoDatenEingegeben: false,

    beschreibung: "",

    ndte: false,
    schneefanggitter: false,
    insel: false,

    bauder: false,

    berechneteWindlast: "0.0 kN/m²",
    berechneteSchneelast: "0.0 kN/m²",

    g: 0.55,
    sk: 0,
    q: 0,

    standortid: 1,
    Region_ID: 0,

    manuelleDacheindeckung: false,
    manuelleSLZ: false,
    manuelleWLZ: true,
    außaussergewoehnlicheSchneelast: false,

    i18n: Ember['default'].inject.service(),

    validations: {
      dacheindeckung: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 3
        }
      },
      g: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 9999.0
        }
      },
      sk: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 99.0
        }
      },
      gelaendehoehe: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 1500.0
        }
      },
      q: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 99.0
        }
      }
    },

    ttG: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 9999.0 [kN/m²]";
    }),
    ttSK: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 99.0 [kN/m²]";
    }),
    ttHuNN: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 1500 [m]";
    }),
    ttQ: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 99.0 [kN/m²]";
    }),
    ttEntfKueste: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 500";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttBmLast: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.01 - 999.0 [kN]";
    }),

    dacheindeckungcontent: [],

    dacheindeckungcontentBauder: [],

    dacheindeckungcontentWuerth: [],

    dacheindeckungLast: ["0", "0.30", "0.55", " 0.75", "0"],

    standortarray: [],

    standortarraywlz1: [],

    standortarraywlz23: [],

    standortarraywlz4: [],

    slzarray: [],

    wlzarray: [],

    gelaendegegebenheitcontent: [],

    init: function init() {

      this._super();

      console.log('init lasten');

      var application = this.controllerFor('application');
      var self = this;
      self.set('bauder', application.get('bauder'));

      if (self.get('bauder') === true) {
        self.setDacheindeckung();
        self.set('dacheindeckung', 4);
      } else {
        self.setDacheindeckung();
        self.set('dacheindeckung', 2);
      }

      self.setSLZarray();
      self.set('slz', 1);
      // self.setGelaendegegebenheitenarray();
      // self.setWLZarray();
      self.set('wlz', 1);
      // self.setStandortarray()

      if (application.get('bauder')) {
        treetools.levels = ["VM_Typ", "l_s"];
      }
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setDacheindeckung();
      self.setSLZarray();
      // self.setGelaendegegebenheitenarray();
      // self.setWLZarray();
      // self.setStandortarray()
    },

    setDefaultValues: function setDefaultValues() {

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);
      lasteinwirkung.set('SLZ_ID', 1);
      lasteinwirkung.set('SLZ_Text', "1");
      lasteinwirkung.set('s_ind', 1);
      lasteinwirkung.set('Gelaende_ID', 0);
      lasteinwirkung.set('StandortID', 1);
      lasteinwirkung.set('Gelaendekategorie_Text', '1');
      lasteinwirkung.set('rauhigkeitID', '1');
    },

    setDacheindeckung: function setDacheindeckung() {
      var self = this;
      var indices = [];
      if (self.get('bauder') === true) {
        indices = [1, 2, 3, 0, 4];
      } else {
        indices = [1, 2, 3, 0];
      }

      self.set('dacheindeckungcontent', self.getSelectFieldContent('dacheindeckungenDB', indices));
    },

    setSLZarray: function setSLZarray() {
      var self = this;
      var indices = [1, 2, 3, 4, 5, 6, 0];
      self.set('slzarray', self.getSelectFieldContent('schneelastenDB', indices));
    },

    setWLZarray: function setWLZarray() {
      var self = this;
      var indices = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
      self.set('wlzarray', self.getSelectFieldContent('windlastenDB', indices));
    },

    setGelaendegegebenheitenarray: function setGelaendegegebenheitenarray() {
      var self = this;
      var indices = [1, 2, 3];
      self.set('gelaendegegebenheitcontent', self.getSelectFieldContent('gelaendegegebenheitenDB', indices));
    },

    setStandortarray: function setStandortarray() {
      var self = this;
      var indices = [0, 1, 2, 3];
      self.set('standortarray', self.getSelectFieldContent('gelaendekategorienDB', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];

      var dbContent = self.getDBContent(db);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'dacheindeckungenDB':
          dbContent = dacheindeckungenDB.contentrs;
          break;
        case 'schneelastenDB':
          dbContent = m01lastreiterDB.schneelasten.UK;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    setValues: function setValues(values) {
      if (self.debug) {
        console.log("values in lasteinwirkung: " + values);
      }

      this.set('openFromFile', true);

      this.set('dacheindeckung', parseInt(values.g_ind));

      if (parseInt(values.g_ind) === 0) {
        this.set('beschreibung', values.EindeckungText);
      }

      this.set('g', parseFloat(values.g).toFixed(2));
      this.set('slz', parseInt(values.SLZ_ID));
      this.set('sk', parseFloat(values.sk).toFixed(2));
      this.set('gelaendehoehe', parseInt(values.HuNN));
      this.set('ndte', values.NorddtTiefebene);
      this.set('schneefanggitter', values.Schneefanggitter);
      this.set('wlz', parseInt(values.WLZ));
      this.set('q', parseFloat(values.qWindDef).toFixed(2));
      this.set('standortid', parseInt(values.StandortID));
      this.set('rauhigkeitID', parseInt(values.rauhigkeitID));

      this.set('region_ID', parseInt(values.Region_ID));

      this.set('entfKueste', parseFloat(values.entfKueste));

      this.set('bmlast', parseFloat(values.V_d));
      this.send('validation', parseFloat(values.V_d), { target: { name: "bmlast" } });

      this.set('openFromFile', false);
    },

    watchNumericalInputs: (function () {
      if (!this.openFromFile) {
        if (String(this.get('bmlast')).indexOf(",") !== -1) {
          this.set('bmlast', this.get('bmlast').replace(",", "."));
          this.setfuehrendeNull('bmlast');
        }

        if (String(this.get('g')).indexOf(",") !== -1) {
          this.set('g', this.get('g').replace(",", "."));
          this.setfuehrendeNull('g');
        }

        if (String(this.get('gelaendehoehe')).indexOf(",") !== -1) {
          this.set('gelaendehoehe', this.get('gelaendehoehe').replace(",", "."));
          this.setfuehrendeNull('gelaendehoehe');
        }

        if (String(this.get('sk')).indexOf(",") !== -1) {
          this.set('sk', this.get('sk').replace(",", "."));
          this.setfuehrendeNull('sk');
        }

        if (String(this.get('q')).indexOf(",") !== -1) {
          this.set('q', this.get('q').replace(",", "."));
          this.setfuehrendeNull('q');
        }

        if (String(this.get('entfKueste')).indexOf(",") !== -1) {
          this.set('entfKueste', this.get('entfKueste').replace(",", "."));
          this.setfuehrendeNull('entfKueste');
        }
      }
    }).observes('bmlast', 'sk', 'q', 'gelaendehoehe', 'g', 'entfKueste'),

    setfuehrendeNull: function setfuehrendeNull(ind) {
      if (this.get(ind).charAt(0) === '.') {
        this.set(ind, '0.');
      }
    },

    watchWLZ: (function () {
      var self = this;

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);
      self.set('standortid', 1);
      self.set('triggerWatchStandortID', !self.get('triggerWatchStandortID'));
    }).observes('wlz'),

    watchGeodatenManuell: (function () {
      var self = this;

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);
      lasteinwirkung.set('geoDatenManuell', self.get('geoDatenManuell'));
    }).observes('geoDatenManuell'),

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

      if (this.get('slz') === 0) {
        this.set('manuelleSLZ', true);
      } else {
        this.set('manuelleSLZ', false);
        this.set('sk', '0.0');
        lasteinwirkung.set('sk', '0');
        this.controllerFor('supercontroller').getSchneelast();
      }

      console.log(' +++++ WLZ: ' + this.get('wlz'));

      lasteinwirkung.set('SLZ_ID', this.get('slz'));
      lasteinwirkung.set('s_ind', this.get('slz'));
      lasteinwirkung.set("SLZ_Text", self.slzarray.findBy('id', self.get('slz')).name.toString());
      lasteinwirkung.set('WLZ', this.get('wlz'));
      lasteinwirkung.set('Gelaende_ID', self.get('region_ID'));
      lasteinwirkung.set('Region_ID', self.get('region_ID'));

      if (self.debug) {
        console.log('slzarray: ');
        console.log(this.slzarray);
        console.log('slz_id: ');
        console.log(lasteinwirkung.get('SLZ_ID'));
        console.log('SLZ_Text: ' + self.getValueFromSelectField(self.get('standortarray'), self.get('standortid')).toString().replace("SLZ ", ""));
      }

      self.set('triggerWatchGeodaten', !self.get('triggerWatchGeodaten'));
    }).observes('slz', 'wlz', 'region_ID', 'triggerWatchComboboxes', 'region_ID'),

    watchDacheindeckung: (function () {

      var self = this;

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

      if (this.get('dacheindeckung') === 0) {
        this.set('manuelleDacheindeckung', true);
        this.set('g', '');
      } else {
        this.set('manuelleDacheindeckung', false);
      }

      lasteinwirkung.set('g_ind', this.get('dacheindeckung'));
      lasteinwirkung.set('g', this.dacheindeckungLast[parseInt(this.get('dacheindeckung'))]);
      self.set('g', Number(this.dacheindeckungLast[parseInt(this.get('dacheindeckung'))]));

      lasteinwirkung.set('EindeckungText', this.dacheindeckungcontent.findBy('id', this.get('dacheindeckung')).name.toString());

      if (self.get('bauder') === true && self.get('dacheindeckung') === 4) {
        // application.set('lasteinwirkungInvalid', true);
      } else {
          // application.set('lasteinwirkungInvalid', false);
          this.lastenAbfragen();
        }

      self.send('validation', self.get('q_p0'), { target: { name: "q_p0" } });
    }).observes('dacheindeckung'),

    watchCheckboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

      lasteinwirkung.set('NorddtTiefebene', this.get('ndte'));
      lasteinwirkung.set('Schneefanggitter', this.get('schneefanggitter'));
    }).observes('ndte', 'schneefanggitter', 'triggerWatchComboboxes'),

    setX3D: function setX3D(value, event) {

      var self = this;

      var application = this.controllerFor('application');
      var x3ddefault = application.get('model').x3ddefault.objectAt(0);

      switch (event.target.name) {
        case 'bmlast':
          if (this.errors.get('bmlast').length === 0) {
            x3ddefault.set('bemessungslast', value);
          }
          break;
      }

      application.get('model').x3ddefault.objectAt(0).set('transformHelper', !this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('transformHelper'));
    },

    actions: {
      schneelastBerechnen: function schneelastBerechnen(event) {
        this.controllerFor('supercontroller').getSchneelast();
      },

      windlastBerechnen: function windlastBerechnen(event) {
        this.controllerFor('supercontroller').getWindlast();
      },

      proceedInput: function proceedInput(event) {

        var self = this;

        var validation = self.get('isValid');

        self.validate().then(function () {
          // all validations pass
          this.transitionToRoute('verbindungsmittel');
        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            //var applicationdata = application.model.objectAt(0);

            //universal
            var applicationdata = application.get('model').application.objectAt(0);
            var applicationdataJSON = JSON.stringify(applicationdata);

            var projektdatendata = application.get('model').projektdaten.objectAt(0);
            var projektdatendataJSON = JSON.stringify(projektdatendata);

            //m06

            var bauteiledata = application.get('model').bauteile.objectAt(0);
            var bauteiledataJSON = JSON.stringify(bauteiledata);

            var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
            var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);

            //m01

            var dachgeometriedata = application.get('model').dachgeometrie.objectAt(0);
            var dachgeometriedataJSON = JSON.stringify(dachgeometriedata);

            var unterkonstruktiondata = application.get('model').unterkonstruktion.objectAt(0);
            var unterkonstruktiondataJSON = JSON.stringify(unterkonstruktiondata);

            var daemmungdata = application.get('model').daemmung.objectAt(0);
            var daemmungdataJSON = JSON.stringify(daemmungdata);

            //universal

            var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
            var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

            var uebergabedaten = $.extend(true, JSON.parse(projektdatendataJSON), JSON.parse(dachgeometriedataJSON), JSON.parse(unterkonstruktiondataJSON), JSON.parse(daemmungdataJSON), JSON.parse(lasteinwirkungdataJSON));

            //Lastannahmen/Parameter_Strukt.vb

            uebergabedaten.TimberElementGl = self.controllerFor('supercontroller').getTimberelementGl();
            uebergabedaten.TimberElementSp = self.controllerFor('supercontroller').getTimberelementSp();
            uebergabedaten.schnee = self.controllerFor('supercontroller').getSchneelastParameter();
            uebergabedaten.wind = self.controllerFor('supercontroller').getWindlastParameter();
            uebergabedaten.gebaeudegeometrie = self.controllerFor('supercontroller').getGebaeudegeometrie();

            // console.log(JSON.stringify(uebergabedaten));

            var JSONdata = JSON.stringify(uebergabedaten);

            if (self.debug) {
              console.log(JSONdata);
            }

            var vmittel = self.controllerFor('verbindungsmittel');
            vmittel.set('loading', true);

            self.set('loading', true);
            self.transitionToRoute('verbindungsmittel');

            var initialized = applicationdata.get('initialized');

            var server = application.get('server');
            var pfad = application.get('pfad');

            console.log('server: ' + server);
            console.log('pfad: ' + pfad);

            if (!initialized) {

              $.ajax({
                type: "POST",
                url: server + "initialisiereBerechnung/",
                //url: "http://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
                data: {
                  kennung: applicationdataJSON,
                  // projektdaten: projektdatendataJSON,
                  paras: JSONdata,
                  modul: "m01",
                  pfad: pfad
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

                var cid = data;
                applicationdata.set('Calculation_ID', cid);
                applicationdata.set('initialized', true);

                $.ajax({
                  type: "POST",
                  url: server + "erzeugeSchraubenliste/",
                  data: {
                    cid: cid
                  }
                }).done(function (data, statusText, xhr) {

                  self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                  console.log(data);

                  // var liste = JSON.parse(data);
                  // self.verbindungsmittelliste = liste;
                  // var core = treetools.buildTree(liste, true);
                  // vmittel.set('schraubenliste', core);

                  vmittel.set('loading', false);
                  vmittel.set('calcid', cid);

                  applicationdata.set('treeLoaded', true);

                  self.controllerFor('supercontroller').auswertungBerechnungsergebnis(data);

                  // if(application.get('bauder') && !application.get('bauderMaster')){
                  //   if(liste.findBy('VM_Okay', "True")){
                  //     console.log(liste);
                  //     console.log(liste.findBy('VM_Okay', "True"));
                  //     console.log(liste.findBy('VM_Okay', "True").ArtNr);
                  //     self.set('ergebnisschraube', liste.findBy('VM_Okay', "True"));
                  //     application.set('verbindungsmittelInvalid', false);
                  //     vmittel.set('screwchanged', !vmittel.get('screwchanged'))
                  //   }else{
                  //     self.set('ergebnisschraube', {"ID":"9999","ArtNr":"", "VM_Name":" Kein passendes Verbindungsmittel verfügbar","Kopfform":"", "d_1":"","l_s":""});
                  //     application.set('verbindungsmittelInvalid', true);
                  //     vmittel.set('screwchanged', !vmittel.get('screwchanged'))
                  //   }
                  // }

                  self.set('loading', false);
                });
              });
            } else {

              var cid = applicationdata.get('Calculation_ID');

              console.log('ist initialisiert');

              $.ajax({
                type: "POST",
                url: server + "erzeugeSchraubenliste/",
                data: {
                  cid: cid
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                console.log('DATA ' + data);

                var vmittel = self.controllerFor('verbindungsmittel');

                // var liste = JSON.parse(data);
                // self.verbindungsmittelliste = liste;
                // var core = treetools.buildTree(liste, true);
                // vmittel.set('schraubenliste', core)

                vmittel.set('loading', false);
                vmittel.set('calcid', cid);

                applicationdata.set('treeLoaded', true);

                self.controllerFor('supercontroller').auswertungBerechnungsergebnis(data);

                // if(application.get('bauder') && !application.get('bauderMaster')){
                //   if(liste.findBy('VM_Okay', "True")){
                //     console.log(liste.findBy('VM_Okay', "True"));
                //     console.log(liste.findBy('VM_Okay', "True").ArtNr);
                //     self.set('ergebnisschraube', liste.findBy('VM_Okay', "True"));
                //     application.set('verbindungsmittelInvalid', false);
                //     vmittel.set('screwchanged', !vmittel.get('screwchanged'))
                //   }else{
                //     self.set('ergebnisschraube', {"ID":"9999","ArtNr":"", "VM_Name":" Kein passendes Verbindungsmittel verfügbar","Kopfform":"", "d_1":"","l_s":""});
                //     application.set('verbindungsmittelInvalid', true);
                //     vmittel.set('screwchanged', !vmittel.get('screwchanged'))
                //   }
                // }

                self.set('loading', false);
              });
            }
          } else {

            console.log('proceed input');
            self.set('displayErrors', true);

            console.log('PROCEED INPUT: lasteinwirkungInvalid --> true');
            application.set('lasteinwirkungInvalid', true);
          }
        });
      },

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {
          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');

          console.log('lasteinwirkung isValid: ' + self.get('isValid'));
          console.log(self.errors);
          console.log('g: ' + self.get('g'));

          if (validation === true) {

            if (self.get('bauder') === true && self.get('dacheindeckung') !== 4) {
              application.set('lasteinwirkungInvalid', false);
            } else if (self.get('bauder') === false) {
              application.set('lasteinwirkungInvalid', false);
              self.set('displayErrors', false);
            }

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);

            application.set('verbindungsmittelInvalid', true);

            var lasteinwirkung = self.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

            // lasteinwirkung.set('V_d', parseFloat(self.get('bmlast')).toFixed(1));
            // self.set('bmlast', parseFloat(self.get('bmlast').replace(",", ".")).toFixed(1));

            if (self.get('dacheindeckung') === 0) {
              lasteinwirkung.set('EindeckungText', self.get('beschreibung'));
            }
            lasteinwirkung.set('g', parseFloat(self.get('g')).toFixed(2));
            lasteinwirkung.set('sk', parseFloat(self.get('sk')).toFixed(2));
            lasteinwirkung.set('HuNN', self.get('gelaendehoehe'));
            lasteinwirkung.set('qWindDef', parseFloat(self.get('q')).toFixed(2));
            lasteinwirkung.set('entfKueste', parseFloat(self.get('entfKueste')).toFixed(1));

            if (event.target.name === 'sk') {
              self.controllerFor('supercontroller').getSchneelast();
            }

            if (event.target.name === 'q') {
              self.controllerFor('supercontroller').getWindlast();
            }
          } else {
            self.set('displayErrors', true);

            console.log('VALIDATION: lasteinwirkungInvalid --> true');
            application.set('lasteinwirkungInvalid', true);
          }
        });
      },

      beschreibungIsSelected: function beschreibungIsSelected() {
        var self = this;
        var beschreibungAktiv = self.get('beschreibungAktiv');
        self.set('beschreibungAktiv', !beschreibungAktiv);

        if (beschreibungAktiv === false) {
          document.getElementsByName('beschreibung')[0].setSelectionRange(0, this.get('beschreibung').length);
        }
      },

      gIsSelected: function gIsSelected() {
        var self = this;
        var gAktiv = self.get('gAktiv');
        self.set('gAktiv', !gAktiv);

        if (gAktiv === false) {
          document.getElementsByName('g')[0].setSelectionRange(0, this.get('g').length);
        }
      },

      skIsSelected: function skIsSelected() {
        var self = this;
        var skAktiv = self.get('skAktiv');
        self.set('skAktiv', !skAktiv);

        if (skAktiv === false) {
          document.getElementsByName('sk')[0].setSelectionRange(0, this.get('sk').length);
        }
      },

      gelaendehoeheIsSelected: function gelaendehoeheIsSelected() {
        var self = this;
        var gelaendehoeheAktiv = self.get('gelaendehoeheAktiv');
        self.set('gelaendehoeheAktiv', !gelaendehoeheAktiv);

        if (gelaendehoeheAktiv === false) {
          document.getElementsByName('gelaendehoehe')[0].setSelectionRange(0, this.get('gelaendehoehe').length);
        }
      },

      qIsSelected: function qIsSelected() {
        var self = this;
        var qAktiv = self.get('qAktiv');
        self.set('qAktiv', !qAktiv);

        if (qAktiv === false) {
          document.getElementsByName('q')[0].setSelectionRange(0, this.get('q').length);
        }
      },

      entfKuesteIsSelected: function entfKuesteIsSelected() {
        var self = this;
        var entfKuesteAktiv = self.get('entfKuesteAktiv');
        self.set('entfKuesteAktiv', !entfKuesteAktiv);

        if (this.get('entfKueste') !== "") {
          var work = parseFloat(this.get('entfKueste').replace(",", "."));
          var rounded = work.toFixed(1);
          this.set('entfKueste', rounded);
        }
        this.send('validation', rounded, { target: { name: "entfKueste" } });

        if (entfKuesteAktiv === false) {
          document.getElementsByName('entfKueste')[0].setSelectionRange(0, this.get('entfKueste').length);
        }
      }
    },

    lastenAbfragen: function lastenAbfragen() {
      console.log('berechne Lasten');
      this.controllerFor('supercontroller').getSchneelast();
      this.controllerFor('supercontroller').getWindlast();
    },

    // getSchneelast: function(){
    //     var self = this;
    //     var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
    //     var initialized = applicationdata.get('initialized');
    //     console.log(initialized);

    //     var application = self.controllerFor('application');

    //     applicationdata.set('treeLoaded', false);
    //     applicationdata.set('initialized', false);
    //     applicationdata.set('pdfErstellt', false);

    //     application.set('verbindungsmittelInvalid', true);

    //     if (self.get('bauder') === false){
    //       application.set('lasteinwirkungInvalid', false);
    //     }

    //     //var applicationdata = application.model.objectAt(0);

    //     //universal
    //     var applicationdata = application.get('model').application.objectAt(0);
    //     var applicationdataJSON = JSON.stringify(applicationdata);

    //     var projektdatendata = application.get('model').projektdaten.objectAt(0);
    //     var projektdatendataJSON = JSON.stringify(projektdatendata);

    //     //m06

    //     var bauteiledata = application.get('model').bauteile.objectAt(0);
    //     var bauteiledataJSON = JSON.stringify(bauteiledata);

    //     var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
    //     var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);

    //     //m01

    //     var dachgeometriedata = application.get('model').dachgeometrie.objectAt(0);
    //     var dachgeometriedataJSON = JSON.stringify(dachgeometriedata);

    //     var unterkonstruktiondata = application.get('model').unterkonstruktion.objectAt(0);
    //     var unterkonstruktiondataJSON = JSON.stringify(unterkonstruktiondata);

    //     var daemmungdata = application.get('model').daemmung.objectAt(0);
    //     var daemmungdataJSON = JSON.stringify(daemmungdata);

    //     //universal

    //     var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
    //     var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

    //     var uebergabedaten = $.extend(true, JSON.parse(projektdatendataJSON), JSON.parse(dachgeometriedataJSON), JSON.parse(unterkonstruktiondataJSON), JSON.parse(daemmungdataJSON), JSON.parse(lasteinwirkungdataJSON));

    //     //Lastannahmen/Parameter_Strukt.vb

    //     var timberelementgl = self.controllerFor('supercontroller').getTimberelementGl();
    //     var timberelementsp = self.controllerFor('supercontroller').getTimberelementSp();

    //     // var timberelementgl = {
    //     //   "Querschnittswerte": {
    //     //     "t_Fastener": daemmungdata.get('glHoehe'),
    //     //     "QuerschnittsID": daemmungdata.get('QuerschnittsID'),
    //     //     "b": daemmungdata.get('glBreite'),
    //     //     "h": daemmungdata.get('glHoehe')
    //     //   }
    //     // };
    //     // var timberelementsp = {
    //     //   "Querschnittswerte": {
    //     //     "b": unterkonstruktiondata.get('TimberElementSp_b'),
    //     //     "h": unterkonstruktiondata.get('TimberElementSp_h')
    //     //   }
    //     // };

    //     var schneelastparas = {
    //       "SLZ_ID": lasteinwirkungdata.get('SLZ_ID'),
    //       "SLZ_Text": lasteinwirkungdata.get('SLZ_Text'),
    //       "Region_ID": lasteinwirkungdata.get('Region_ID'),
    //       "RegionText": "Region Text", // nicht notwendig
    //       "WindgeschuetzteLage": "Windgeschuetzte Lage", // nicht notwendig
    //       "Fussnote": "Fussnote", // nicht notwendig
    //       "Zone": "Zone", // nur bei alten lastannahmen notwendig
    //       "A": lasteinwirkungdata.get('HuNN'), // HuNN
    //       // "sk": lasteinwirkungdata.get('sk'),
    //       "sk_manuell": lasteinwirkungdata.get('sk'),
    //       "NorddtTiefebene": lasteinwirkungdata.get('NorddtTiefebene'),
    //       "Schneefanggitter": lasteinwirkungdata.get('Schneefanggitter'),
    //       "Gelaende_ID": lasteinwirkungdata.get('Gelaende_ID'),
    //       "aussergewoehnlichSchnee": lasteinwirkungdata.get('aussergewoehnlichSchnee'),
    //       "Formbeiwert_DK": lasteinwirkungdata.get('Formbeiwert_DK'),
    //       "h0": lasteinwirkungdata.get('h0'),
    //       "DF_1": {
    //         "ALFA_Grad": dachgeometriedata.get('ALFA_Grad'),
    //         "Schneefanggitter": lasteinwirkungdata.get('Schneefanggitter')
    //       }
    //     };

    //     uebergabedaten.TimberElementGl = timberelementgl;
    //     uebergabedaten.TimberElementSp = timberelementsp;
    //     uebergabedaten.Schnee = schneelastparas;

    //     var JSONdata = JSON.stringify(uebergabedaten);

    //     if (self.debug) {
    //       if (self.debug) {
    //         console.log('applicationdataJSON');
    //         console.log(applicationdataJSON);
    //         console.log('JSON.stringify(schneelastparas)');
    //         console.log(JSON.stringify(schneelastparas));
    //         console.log('dachgeometriedataJSON');
    //         console.log(dachgeometriedataJSON);
    //       }
    //     }

    //     self.set('loading', true);

    //     var cid = applicationdata.get('Calculation_ID');

    //     var server = application.get('server');
    //     var pfad = application.get('pfad');

    //     $.ajax({
    //       type: "POST",
    //       url: server + "m01/DE/berechneSchneelast/",
    //       data: {
    //         kennung: applicationdataJSON,
    //         schneeparameter: JSON.stringify(schneelastparas),
    //         gebaeudeparameter: dachgeometriedataJSON
    //       }
    //     }).done(function(data) {

    //       data = data.replace(",", ".");

    //       var value = parseFloat(data);
    //       if (self.debug){
    //         console.log('schneelast - data: '+data);
    //       }

    //       if (value > 0 && value < 10){
    //         value = value.toFixed(2);
    //       }else{
    //         value = value.toFixed(1);
    //       }

    //       if (value.toString() === "999.0"){
    //         value = " ";
    //       }

    //       self.set('berechneteSchneelast', value + " kN/m²");
    //       self.set('loading', false);

    //       // rückgabewert als ergebnis in lasteinwirkung setzen
    //     });
    //     // hier endet das windlast zeug
    // },

    // getWindlast:function(){

    //     var self = this;
    //     var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
    //     var initialized = applicationdata.get('initialized');
    //     console.log(initialized);

    //     var application = self.controllerFor('application');

    //     applicationdata.set('treeLoaded', false);
    //     applicationdata.set('initialized', false);
    //     applicationdata.set('pdfErstellt', false);

    //     application.set('verbindungsmittelInvalid', true);

    //     if (self.get('bauder') === false){
    //       self.set('displayErrors', false);
    //       application.set('lasteinwirkungInvalid', false);
    //     }

    //     //var applicationdata = application.model.objectAt(0);

    //     //universal
    //     var applicationdata = application.get('model').application.objectAt(0);
    //     var applicationdataJSON = JSON.stringify(applicationdata);

    //     var projektdatendata = application.get('model').projektdaten.objectAt(0);
    //     var projektdatendataJSON = JSON.stringify(projektdatendata);

    //     //m06

    //     var bauteiledata = application.get('model').bauteile.objectAt(0);
    //     var bauteiledataJSON = JSON.stringify(bauteiledata);

    //     var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
    //     var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);

    //     //m01

    //     var dachgeometriedata = application.get('model').dachgeometrie.objectAt(0);
    //     var dachgeometriedataJSON = JSON.stringify(dachgeometriedata);

    //     var unterkonstruktiondata = application.get('model').unterkonstruktion.objectAt(0);
    //     var unterkonstruktiondataJSON = JSON.stringify(unterkonstruktiondata);

    //     var daemmungdata = application.get('model').daemmung.objectAt(0);
    //     var daemmungdataJSON = JSON.stringify(daemmungdata);

    //     //universal

    //     var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
    //     var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

    //     var uebergabedaten = $.extend(true, JSON.parse(projektdatendataJSON),JSON.parse(dachgeometriedataJSON), JSON.parse(unterkonstruktiondataJSON), JSON.parse(daemmungdataJSON), JSON.parse(lasteinwirkungdataJSON));

    //     //Lastannahmen/Parameter_Strukt.vb

    //     var timberelementgl = self.controllerFor('supercontroller').getTimberelementGl();
    //     var timberelementsp = self.controllerFor('supercontroller').getTimberelementSp();

    //     // var timberelementgl = {
    //     //   "Querschnittswerte": {
    //     //     "t_Fastener": daemmungdata.get('glHoehe'),
    //     //     "QuerschnittsID": daemmungdata.get('QuerschnittsID'),
    //     //     "b": daemmungdata.get('glBreite'),
    //     //     "h": daemmungdata.get('glHoehe')
    //     //   }
    //     // };
    //     // var timberelementsp = {
    //     //   "Querschnittswerte": {
    //     //     "b": unterkonstruktiondata.get('TimberElementSp_b'),
    //     //     "h": unterkonstruktiondata.get('TimberElementSp_h')
    //     //   }
    //     // };

    //     var windlastparas = {
    //       "v_b0":lasteinwirkungdata.get('v_b0'),
    //       "entfKueste":lasteinwirkungdata.get('entfKueste'),
    //       "insel":lasteinwirkungdata.get('insel'),
    //       "qk_manuell":lasteinwirkungdata.get('qWindDef'),
    //       "Windzone":lasteinwirkungdata.get('WLZ'),
    //       "rauhigkeitID": lasteinwirkungdata.get('rauhigkeitID'),
    //       "Gelaendekategorie":lasteinwirkungdata.get('StandortID'),
    //       "Gelaendekategorie_Text": lasteinwirkungdata.get('Gelaendekategorie_Text'),
    //       "ExponierteLage":"false",
    //       "gelaendehoehe":lasteinwirkungdata.get('HuNN'),
    //       "Windzone_Text":lasteinwirkungdata.get('Text'),
    //     };

    //     uebergabedaten.TimberElementGl = timberelementgl;
    //     uebergabedaten.TimberElementSp = timberelementsp;
    //     uebergabedaten.Wind = windlastparas;

    //     var JSONdata = JSON.stringify(uebergabedaten);

    //     if (self.debug) {
    //       console.log('applicationdataJSON');
    //       console.log(applicationdataJSON);
    //       console.log('JSON.stringify(windlastparas)');
    //       console.log(JSON.stringify(windlastparas));
    //       console.log('dachgeometriedataJSON');
    //       console.log(dachgeometriedataJSON);
    //     }

    //     self.set('loading', true);

    //     var server = application.get('server');
    //     var pfad = application.get('pfad');

    //     $.ajax({
    //       type: "POST",
    //       url: server + "m01/DE/berechneWindlast/",
    //       data: {
    //         kennung: applicationdataJSON,
    //         windparameter: JSON.stringify(windlastparas),
    //         gebaeudeparameter: dachgeometriedataJSON
    //       }
    //     }).done(function(data) {

    //       data = data.replace(",", ".");
    //       var value = parseFloat(data).toFixed(2)

    //       if (self.debug){
    //         console.log('windlast - data: '+data);
    //       }
    //       self.set('berechneteWindlast', value + " kN/m²");
    //       self.set('loading', false);
    //       // rückgabewert als ergebnis in lasteinwirkung setzen
    //     });

    //     // hier endet das windlast zeug
    // },

    watchGeodaten: (function () {
      var self = this;

      self.set('geoDatenEingegeben', true);
      self.set('geoDatenManuell', true);

      self.controllerFor('application').get('model').lasteinwirkung.objectAt(0).set('HuNN', this.get('gelaendehoehe'));

      this.lastenAbfragen();
    }).observes('entfKueste', 'slz', 'gelaendehoehe', 'ndte', 'schneefanggitter', 'sk', 'q', 'triggerWatchGeodaten')

  });

});