define('m01new/components/materia-l', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Material = Ember['default'].Component.extend({
    tagName: 'material',
    attributeBindings: ['diffusecolor', 'transparency', 'ambientintensity', 'emissivecolor', 'shininess', 'specularcolor'],
    ambientintensity: "0.2",
    emissivecolor: "0,0,0",
    shininess: "0.2",
    specularcolor: "0,0,0",

    diffusecolor: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values;

      var item = bauteile.findBy('id', name);

      if ((name === 'schalung' || name === 'sparren1') && bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') !== '') {
        switch (bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung')) {
          case 'holz':
            values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
            break;
          case 'beton':
            values = 0.2 + " " + 0.4 + " " + 0.4;
            break;
          case 'porenbeton':
            values = 0.6 + " " + 0.6 + " " + 0.6;
            break;
        }
      } else if (name === 'grundlatte1' && x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('grundlatteMaterial')) {
        values = 0.8 + " " + 0.8 + " " + 0.2;
      } else {
        values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      }

      return values;
    }).property('model.firstObject.farbeGeandert'),

    transparency: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value;
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === 'box' && !x3d.get('istGitterModell')) {
        value = item.get('appearances').objectAt(0).get('transparency');
      } else if (item.get('typ') === 'box' && x3d.get('istGitterModell')) {
        value = 1;
      } else {
        value = 0;
      }

      return value;
    }).property('model.m06.bauteile', 'model.firstObject.istGitterModell')
  });

  exports['default'] = Material;

});