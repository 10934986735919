define('m01new/components/transfor-m', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'transfor-m',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,

    center: (function () {
      var values = '0 0 0';

      return values;
    }).property(''),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      var values = '0 0 0';
      var y;
      var xVerschiebung, yVerschiebung, zVerschiebung;

      if (item.get('typ') === 'box') {

        xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
        yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
        zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

        var schalungsHoehe = 0;

        if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') !== '') {
          schalungsHoehe = this.get('model').objectAt(0).get('bauteile').findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10;
        }

        switch (name) {
          case 'schalung':
            yVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y') / 10;
            break;
          case 'daemmung1':
            if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('traufkonstruktion') && !x3d.get('bauder')) {
              xVerschiebung = xVerschiebung + x3d.get('bauteile').findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).get('x') / 20;
            }
            yVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y') / 10 + schalungsHoehe;
            break;
          case 'daemmung2':
            if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('traufkonstruktion') && !x3d.get('bauder')) {
              xVerschiebung = xVerschiebung + x3d.get('bauteile').findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).get('x') / 20;
            }
            yVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y') / 10 + schalungsHoehe + this.get('model').objectAt(0).get('bauteile').findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('y') / 10;
            break;
          case 'grundlatte1':
            yVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y') / 10 + schalungsHoehe + this.get('model').objectAt(0).get('bauteile').findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('y') / 10 + this.get('model').objectAt(0).get('bauteile').findBy('id', 'daemmung2').get('boxsizes').objectAt(0).get('y') / 10;
            if (name === 'grundlatte1' && x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('grundlatteMaterial')) {
              zVerschiebung = 0;
            } else {
              zVerschiebung = -this.get('model').objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
            }
            break;
          case 'grundlatte2':
            yVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y') / 10 + schalungsHoehe + this.get('model').objectAt(0).get('bauteile').findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('y') / 10 + this.get('model').objectAt(0).get('bauteile').findBy('id', 'daemmung2').get('boxsizes').objectAt(0).get('y') / 10;
            zVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
            break;
          case 'sparren1':
            zVerschiebung = -this.get('model').objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
            zVerschiebung = -this.get('model').objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
            if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') === 'beton') {
              zVerschiebung = 0;
            }
            break;
          case 'sparren2':
            zVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
            zVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
            break;
          case 'traufkonstruktion1':
            if (x3d.get('bauder')) {
              xVerschiebung = -x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20 + 6;
            } else {
              xVerschiebung = -x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20 + this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
            }
            yVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y') / 10 + schalungsHoehe;
            zVerschiebung = -this.get('model').objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
            break;
          case 'traufkonstruktion2':
            if (x3d.get('bauder')) {
              xVerschiebung = -x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20 + 6;
            } else {
              xVerschiebung = -x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20 + this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
            }
            yVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y') / 10 + schalungsHoehe;
            zVerschiebung = this.get('model').objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
            break;
        }

        values = xVerschiebung + '  ' + yVerschiebung + '  ' + zVerschiebung;
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var values;

      if (name.substring(0, 8) === 'schraube') {
        values = '0 0 0 0';
      } else {
        values = bauteile.findBy('id', name).get('rotations').objectAt(0).get('x') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('y') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('z') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('winkel');
      }
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    getOutline: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var istVolumen = false;

      if (bauteile.findBy('id', name).get('typ') === 'box') {
        istVolumen = true;
      }
      return istVolumen;
    }).property(''),

    istHolz: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var value = true;

      if (name === 'sparren1' && bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') === 'beton' || name.substring(0, 8) === 'daemmung' || name === 'grundlatte1' && bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('grundlatteMaterial')) {
        value = false;
      }
      return value;
    }).property('model.firstObject.transformHelper'),

    istMDE: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var value = true;

      if (name === 'grundlatte1' && bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('grundlatteMaterial')) {
        value = false;
      }
      return value;
    }).property('model.firstObject.transformHelper'),

    istNichtBetonUnterkonstruktion: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var value = true;

      if (name === 'sparren1' && bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') === 'beton') {
        value = false;
      }
      return value;
    }).property('model.firstObject.transformHelper'),

    abstaendeAnzeigen: (function () {
      var x3d = this.get('model').objectAt(0);
      return x3d.get('abstaendeAnzeigen');
    }).property('model.firstObject.abstaendeAnzeigen'),

    ansichtLinks: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('istFassade') === false && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }
      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtFront: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('istFassade') === false && (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true && name !== 'lager1') || bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') && name === 'lager1') {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtOben: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('istFassade') === false && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint')
  });

  exports['default'] = Transform;

});