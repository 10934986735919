define('m01new/models/unterkonstruktion', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var unterkonstruktion = DS['default'].Model.extend({

    UK_ind: DS['default'].attr('string'),
    MatKeyTimberElementSp: DS['default'].attr('string'),
    FKLKeyTimberElementSp: DS['default'].attr('string'),
    TimberElementSp_b: DS['default'].attr('string'),
    TimberElementSp_h: DS['default'].attr('string'),
    e_Sp: DS['default'].attr('string'),
    tUK1: DS['default'].attr('string'),
    Winkel_ZugSchraube_RAD: DS['default'].attr('string'),
    Winkel_DruckSchraube_RAD: DS['default'].attr('string'),
    QuerschnittsID: DS['default'].attr('string')

  });

  unterkonstruktion.reopenClass({
    FIXTURES: [{
      id: 1,
      UK_ind: "0",
      MatKeyTimberElementSp: "0",
      FKLKeyTimberElementSp: "5",
      TimberElementSp_b: "10",
      TimberElementSp_h: "20",
      e_Sp: "80",
      tUK1: "0",
      Winkel_ZugSchraube_RAD: "60",
      Winkel_DruckSchraube_RAD: "60",
      QuerschnittsID: "1"

    }]
  });

  exports['default'] = unterkonstruktion;

});