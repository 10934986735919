define('m01new/models/daemmung', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var daemmung = DS['default'].Model.extend({

    befestigungsVariante: DS['default'].attr('string'),
    NameDae1: DS['default'].attr('string'),
    d_Dae1: DS['default'].attr('string'),
    E_Dae: DS['default'].attr('string'),
    DaeDruckfest: DS['default'].attr('boolean'),
    ArtNr1: DS['default'].attr('string'),
    NameDae2: DS['default'].attr('string'),
    d_Dae2: DS['default'].attr('string'),
    ArtNr2: DS['default'].attr('string'),
    MatKeyTimberElementGl: DS['default'].attr('string'),
    FKLKeyTimberElementGl: DS['default'].attr('string'),
    glBreite: DS['default'].attr('string'),
    glHoehe: DS['default'].attr('string'),
    L_Gl: DS['default'].attr('string'),
    schraubenabstaende: DS['default'].attr('string'),
    verklebt: DS['default'].attr('boolean'),
    Sima10: DS['default'].attr('string'),
    gl_ind: DS['default'].attr('string'),
    QuerschnittsID: DS['default'].attr('string'),
    SelectedVarId: DS['default'].attr('string'),
    mde: DS['default'].attr('boolean'),
    grundlatteMaterial: DS['default'].attr('string'),
    grundlatteAbmessungID: DS['default'].attr('string')
  });

  daemmung.reopenClass({
    FIXTURES: [{
      id: 1,
      befestigungsVariante: "0",
      NameDae1: "",
      d_Dae1: "0.0",
      E_Dae: "0.0",
      DaeDruckfest: true,
      ArtNr1: "000",
      NameDae2: "",
      d_Dae2: "0.0",
      ArtNr2: "",
      MatKeyTimberElementGl: "0",
      FKLKeyTimberElementGl: "5",
      glBreite: "6",
      glHoehe: "4",
      L_Gl: "0",
      schraubenabstaende: "175",
      verklebt: false,
      Sima10: "0",
      gl_ind: "2",
      QuerschnittsID: "1",
      SelectedVarId: "0",
      mde: false,
      grundlatteMaterial: "0",
      grundlatteAbmessungID: "2"
    }]
  });

  exports['default'] = daemmung;

});