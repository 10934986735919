define('m01new/components/bo-x', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Box = Ember['default'].Component.extend({
    tagName: 'box',
    attributeBindings: ['size', 'solid', 'ccw', 'usegeocache', 'lit'],
    solid: true,
    ccw: true,
    usegeocache: true,
    lit: true,

    size: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values;

      return values;
    }).property('model.firstObject.transformHelper')
  });

  exports['default'] = Box;

});