define('m01new/router', ['exports', 'ember', 'm01new/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.reopen({
    location: 'history'
  });

  Router.map(function () {
    this.route('anschlussgeometrie');
    this.route('bauteile');
    this.route('ergebnis');
    this.route('lasteinwirkung');
    this.route('lasteinwirkungat');
    this.route('lasteinwirkungfr');
    this.route('lasteinwirkungch');
    this.route('lasteinwirkungcz');
    this.route('lasteinwirkungsk');
    this.route('lasteinwirkunghu');
    this.route('lasteinwirkungro');
    this.route('lasteinwirkungsi');
    this.route('lasteinwirkungit');
    this.route('lasteinwirkunguk');
    this.route('lasteinwirkungpl');
    this.route('lasteinwirkungnl');
    this.route('lasteinwirkungbe');
    this.route('lasteinwirkunglu');
    this.route('lasteinwirkungno');
    this.route('lasteinwirkungdk');
    this.route('lasteinwirkunghr');
    this.route('lasteinwirkungse');
    this.route('projektdaten');
    this.route('verbindungsmittel');

    // this.resource('projektdatens', {path: '/'}, function(){});
    this.route('impressum');
    this.route('printview');
    this.route('contact');
    this.route('dachgeometrie');
    this.route('unterkonstruktion');
    this.route('daemmung');
    this.route('downloadcenter');
  });

  exports['default'] = Router;

});