define('m01new/controllers/contact', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    message: "",
    sname: "",
    email: "",
    testemail: "",

    sendingMail: false,

    nachrichtVerfassen: true,

    actions: {

      sendMessage: function sendMessage() {

        var self = this;

        if (self.get('testemail') === "") {

          console.log('sendMessage()');

          self.set('sendingMail', true);

          var messageToSend = self.get('message');
          var nameOfSender = self.get('sname');
          var email = self.get('email');

          var self = this;
          var application = self.controllerFor('application');

          //universal
          var applicationdata = application.get('model').application.objectAt(0);
          var applicationdataJSON = JSON.stringify(applicationdata);

          var projektdatendata = application.get('model').projektdaten.objectAt(0);
          var projektdatendataJSON = JSON.stringify(projektdatendata);

          //m06

          var bauteiledata = application.get('model').bauteile.objectAt(0);
          var bauteiledataJSON = JSON.stringify(bauteiledata);

          var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
          var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);

          //m01

          var dachgeometriedata = application.get('model').dachgeometrie.objectAt(0);
          var dachgeometriedataJSON = JSON.stringify(dachgeometriedata);

          var unterkonstruktiondata = application.get('model').unterkonstruktion.objectAt(0);
          var unterkonstruktiondataJSON = JSON.stringify(unterkonstruktiondata);

          var daemmungdata = application.get('model').daemmung.objectAt(0);
          var daemmungdataJSON = JSON.stringify(daemmungdata);

          //universal

          var lasteinwirkung = self.controllerFor('lasteinwirkung');
          var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
          var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

          var uebergabedaten = $.extend(true, JSON.parse(projektdatendataJSON), JSON.parse(dachgeometriedataJSON), JSON.parse(unterkonstruktiondataJSON), JSON.parse(daemmungdataJSON), JSON.parse(lasteinwirkungdataJSON));

          //Lastannahmen/Parameter_Strukt.vb

          var timberelementgl = {
            "Querschnittswerte": {
              "t_Fastener": daemmungdata.get('glHoehe'),
              "QuerschnittsID": daemmungdata.get('QuerschnittsID'),
              "b": daemmungdata.get('glBreite'),
              "h": daemmungdata.get('glHoehe')
            }
          };
          var timberelementsp = {
            "Querschnittswerte": {
              "b": unterkonstruktiondata.get('TimberElementSp_b'),
              "h": unterkonstruktiondata.get('TimberElementSp_h')
            }
          };
          var schneelastparas = {
            "SLZ_ID": lasteinwirkungdata.get('SLZ_ID'),
            "SLZ_Text": lasteinwirkung.slzarray.findBy('id', lasteinwirkungdata.get('SLZ_ID')).name.toString(), //inhalt der comobox

            "Region_ID": "1", // nicht notwendig
            "RegionText": "Region Text", // nicht notwendig
            "WindgeschuetzteLage": "false", // nicht notwendig
            "Fussnote": "0", // nicht notwendig
            "Zone": "Zone", // nur bei alten lastannahmen notwendig
            "A": lasteinwirkungdata.get('HuNN'), // HuNN
            "sk_manuell": lasteinwirkungdata.get('sk'),
            "NorddtTiefebene": lasteinwirkungdata.get('NorddtTiefebene'),
            "Schneefanggitter": lasteinwirkungdata.get('Schneefanggitter'),
            "Gelaende_ID": lasteinwirkungdata.get('Gelaende_ID'),
            "aussergewoehnlichSchnee": lasteinwirkungdata.get('aussergewoehnlichSchnee'),
            "Formbeiwert_DK": lasteinwirkungdata.get('Formbeiwert_DK'),
            "h0": lasteinwirkungdata.get('h0'),

            "DF_1": {
              "ALFA_Grad": dachgeometriedata.get('ALFA_Grad'),
              "Schneefanggitter": lasteinwirkungdata.get('Schneefanggitter')
            }
          };
          var windlastparas = {
            "v_b0": lasteinwirkungdata.get('v_b0'),
            "qk_manuell": lasteinwirkungdata.get('qWindDef'),
            "Windzone": lasteinwirkungdata.get('WLZ'),
            "Gelaendekategorie": lasteinwirkungdata.get('StandortID'),
            "Gelaendekategorie_Text": lasteinwirkungdata.get('Gelaendekategorie_Text'),
            "ExponierteLage": lasteinwirkungdata.get('StandortID'),
            "gelaendehoehe": lasteinwirkungdata.get('HuNN'),
            "WindText": "lasteinwirkungdata.get('Text')",
            "EntfKuestee": lasteinwirkungdata.get('EntfKuestee')

          };

          uebergabedaten.TimberElementGl = timberelementgl;
          uebergabedaten.TimberElementSp = timberelementsp;
          uebergabedaten.Schnee = schneelastparas;
          uebergabedaten.Wind = windlastparas;

          var JSONdata = JSON.stringify(uebergabedaten);

          if (self.debug) {
            console.log("complete: " + JSONdata);
          }

          var application = self.controllerFor('application');
          var server = application.get('server');
          var pfad = application.get('pfad');

          var cid = applicationdata.get('Calculation_ID');

          var subject = 'M01 HTML CLIENT - Kontaktformular / Anfrage';
          var suffix = "wu01";
          if (application.get('bauder') === true) {
            subject = 'M01 Bauder HTML CLIENT - Kontaktformular / Anfrage';
            suffix = "BA01";
          }

          $.ajax({
            type: "POST",
            url: server + "sendeEmail/",
            data: {
              kennung: applicationdataJSON,
              paras: JSONdata,
              suffix: suffix,
              cid: cid,
              body: messageToSend,
              name: nameOfSender,
              subj: 'M01 HTML CLIENT - Kontaktformular / Anfrage',
              sender: email,
              receiver: "support@swg-engineering.de"
            }
          }).done(function (data) {
            self.set('sendingMail', false);
            self.set('nachrichtVerfassen', false);
            console.log(data);
            // datei öffnen, bzw. link anzeigen
          });
        }
      },

      neueNachrichtVerfassen: function neueNachrichtVerfassen() {
        var self = this;
        self.set('message', "");
        self.set('nachrichtVerfassen', false);
        this.transitionToRoute('projektdaten');
        self.set('nachrichtVerfassen', true);
      }
    }
  });

});