define('m01new/components/schraub-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schraube = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraub-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      var name = this.get('name');

      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';

      var name = this.get('name');

      var bauteile = this.get('model').objectAt(0).get('bauteile');

      var drehwinckelZAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').findBy('id', name).get('z');

      var x = bauteile.findBy('id', name).get('translations').findBy('id', name).get('x') / 10;
      var y = bauteile.findBy('id', name).get('translations').findBy('id', name).get('y') / 10;
      var zrel = bauteile.findBy('id', name).get('translations').findBy('id', name).get('z') / 10;
      var z;

      if (zrel < 0) {
        z = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20);
      } else if (zrel > 0) {
        z = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
      }

      if (name !== 'schraubestandard21' && name !== 'schraubestandard25') {
        var sparrenhoehe = bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y');
        var grundlattenhoehe = bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y');
        var schalungsdicke = bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y');
        var daemmungsdicke = Number(bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('y')) + Number(bauteile.findBy('id', 'daemmung2').get('boxsizes').objectAt(0).get('y'));

        var hoeheunterkonstruktion = Number(sparrenhoehe) + Number(grundlattenhoehe) + Number(schalungsdicke) + Number(daemmungsdicke);

        var delta = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('hoeheUnterkonstruktion');

        if (name === 'schraubestandard22' || name === 'schraubestandard26') {
          var drehwinckelZAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').findBy('id', name).get('z');
          var l = Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('length'));
          delta = l * Math.cos(drehwinckelZAchse) + Number(bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y'));
        }

        y = hoeheunterkonstruktion / 10 - delta / 10;
      }

      if (name === 'schraubestandard1' || name === 'schraubestandard2' || name === 'schraubestandard3' || name === 'schraubestandard4' || name === 'schraubestandard5' || name === 'schraubestandard6' || name === 'schraubestandard7' || name === 'schraubestandard8') {

        var winkel = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').findBy('id', name).get('z');

        var laengeUnterkonstruktion = this.get('model').objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion');
        var hoehe = this.get('model').objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('hoeheUnterkonstruktion');
        var laenge = hoehe / Math.cos(Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('z')));

        var headID = Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('headformID'));
        if (headID === 1 || headID === 2 || headID === 3 || headID === 7 || headID === 10 || headID === 11) {
          laenge = laenge + 3 * Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('headheight'));
        }

        var d = bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('diameter');
        x = x * 10;

        // console.log('##### '+name+ '#####');
        // console.log(bauteile.findBy('id', name).get('translations').findBy('id', name).get('x'));
        // console.log('x: '+x);
        // console.log('Math.abs(x): '+Math.abs(x));
        // console.log('x-x*Math.abs(x): '+(x-x/Math.abs(x)));
        // console.log('laenge: '+ laenge);
        // console.log('winkel: '+ winkel);
        // console.log('laengeUnterkonstruktion: '+ laengeUnterkonstruktion);

        // x = (x-x/Math.abs(x))/5 * (Math.abs(laenge) * Math.sin(Math.abs(winkel)) + 3*d) + Math.abs(x-x/Math.abs(x)) * (12.5*d*x/Math.abs(x))/10;

        var delta = 0.3;

        if (2 * Math.abs(laenge) * Math.sin(Math.abs(winkel) + 0.3) < 12.5 * d) {
          delta = 12.5 * d;
        }

        // console.log('(2*Math.abs(laenge) * Math.sin(Math.abs(winkel)+0.3) = '+(2*Math.abs(laenge) * Math.sin(Math.abs(winkel)+0.3)));
        // console.log('12.5*d = '+(12.5*d));

        // x = (x-x/Math.abs(x))/5 * (Math.abs(laenge) * Math.sin(Math.abs(winkel)) + 3*d) + x/Math.abs(x)*delta;

        x = x * (laengeUnterkonstruktion - 1.5 * d) / 40 + winkel / Math.abs(winkel) * (Math.abs(laenge) * Math.sin(Math.abs(winkel)) + 3 * d) / 10;

        // console.log('x-Position: '+ x);
        // console.log(' ');
      }

      if (name === 'schraubestandard28' || name === 'schraubestandard29' || name === 'schraubestandard30' || name === 'schraubestandard31' || name === 'schraubestandard33' || name === 'schraubestandard34' || name === 'schraubestandard35' || name === 'schraubestandard36') {
        y = Number(bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y')) / 10 + 0.15;
      }

      values = x + ' ' + y + ' ' + z;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    // Rotation in der XY-Ebene, um die z-Achse
    rotation: (function () {
      var name = this.get('name');
      var drehwinckelZAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').findBy('id', name).get('z');
      var drehwinckelYAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').findBy('id', name).get('y');
      var values = '';

      // if (drehwinckelZAchse === 0){

      values = '0 0 1 ' + drehwinckelZAchse;

      // }else{
      //
      //   if ((!this.get('model').objectAt(0).get('schraubenGekreuzt')) && (drehwinckelZAchse > 1.5707)){
      //     drehwinckelZAchse = 1.5707-(drehwinckelZAchse-1.5707);
      //   }
      //
      //   var xWertRotationsachse = Math.sin(drehwinckelYAchse);
      //   var zWertRotationsachse = Math.cos(drehwinckelYAchse);
      //
      //   values = xWertRotationsachse + ' 0 '+ zWertRotationsachse + ' ' + (drehwinckelZAchse-1.5707);
      //
      // }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt')
  });

  exports['default'] = Schraube;

});