define('m01new/models/projektdaten', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var projektdaten = DS['default'].Model.extend({

		bname: DS['default'].attr('string'),
		bvname: DS['default'].attr('string'),
		bvplz: DS['default'].attr('string'),
		bvstrasse: DS['default'].attr('string', {
			defaultValue: function defaultValue() {
				return 'false';
			}
		}),
		bvort: DS['default'].attr('string'),
		vname: DS['default'].attr('string'),
		vstrasse: DS['default'].attr('string'),
		vplz: DS['default'].attr('string'),
		vort: DS['default'].attr('string'),
		vtel: DS['default'].attr('string'),
		vfax: DS['default'].attr('string'),
		vemail: DS['default'].attr('string'),
		bezeichnung: DS['default'].attr('string'),
		beschreibung: DS['default'].attr('string'),
		kdnum: DS['default'].attr('string'),
		bemerkungstext: DS['default'].attr('string')

	});

	projektdaten.reopenClass({
		FIXTURES: [{
			id: 1,
			bname: '',
			bvname: '',
			bvplz: '',
			bvstrasse: '',
			bvort: '',
			vname: '',
			vstrasse: '',
			vplz: '',
			vort: '',
			vtel: '',
			vfax: '',
			vemail: '',
			bezeichnung: '',
			beschreibung: '',
			kdnum: '',
			bemerkungstext: ''
		}]
	});

	exports['default'] = projektdaten;

});