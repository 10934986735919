define('m01new/models/gebaeudemass', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    ausfuehrung: DS['default'].attr('string'),
    firsthoehe: DS['default'].attr('number'),
    dachneigung: DS['default'].attr('number'),
    dachneigung2: DS['default'].attr('number'),
    gebaeudebreite: DS['default'].attr('number'),
    gebaeudelaenge: DS['default'].attr('number'),
    giebelueberstand: DS['default'].attr('number'),
    traufeueberstand: DS['default'].attr('number'),
    firstueberstand: DS['default'].attr('number'),
    sparrenabstand: DS['default'].attr('number'),
    daemmstoffdicke: DS['default'].attr('number'),
    hoeheUnterkonstruktion: DS['default'].attr('number'),
    laengeUnterkonstruktion: DS['default'].attr('number'),
    schalung: DS['default'].attr('string'),
    traufkonstruktion: DS['default'].attr('boolean'),
    grundlatteMaterial: DS['default'].attr('boolean')
  });

});