define('m01new/routes/verbindungsmittel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    setupController: function setupController() {
      this.controllerFor('application').set('currentPath', 'verbindungsmittel');
      this.controllerFor('application').miniertX3D();
    }

  });

});