define('m01new/models/anschlussgeometrie', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var anschlussgeometrie = DS['default'].Model.extend({

		ALFA_Vertical_GRAD: DS['default'].attr('string'),
		ALFA_Horizontal_GRAD: DS['default'].attr('string'),
		SrVar: DS['default'].attr('string'),
		SrPaare: DS['default'].attr('string'),
		Max_nSrGrp: DS['default'].attr('string'),
		QuerZugNW: DS['default'].attr('boolean')

	});

	anschlussgeometrie.reopenClass({
		FIXTURES: [{
			id: 1,
			ALFA_Vertical_GRAD: "0",
			ALFA_Horizontal_GRAD: "90",
			SrVar: '1',
			SrPaare: 'true',
			Max_nSrGrp: '0',
			QuerZugNW: true
		}]
	});

	exports['default'] = anschlussgeometrie;

});