define('m01new/components/indexedlinese-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'indexedlinese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    translation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var bezug = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'lastenPfeil') {
        x = item.get('translations').objectAt(0).get('x');
        y = item.get('translations').objectAt(0).get('y');
        z = item.get('translations').objectAt(0).get('z');
        cords = x + ' ' + y + ' ' + z;
      }

      if (name === 'dachgeometrie' && (typ === 'satteldach' || typ === 'pultdach' || typ === 'walmdach' || typ === 'breite' || typ === 'laenge' || typ === 'hoehe' || typ === 'firstueberstand' || typ === 'traufeueberstand' || typ === 'giebelueberstand' || typ === 'giebellaenge')) {

        x = 0;
        y = -item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / (this.objektSkalierung * 3);
        z = 0;
        cords = x + ' ' + y + ' ' + z;
      }

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values;

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'lastenPfeil') {
        values = item.get('rotations').objectAt(0).get('x') + " " + item.get('rotations').objectAt(0).get('y') + " " + item.get('rotations').objectAt(0).get('z') + " " + item.get('rotations').objectAt(0).get('winkel');
      }

      if (name === 'hWinkel') {
        values = '1 0 0 -1.5707';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hwinkel = Number(x3d.get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;

      var x = 0;
      var y = 0;
      var z = 0;

      var skalierungsfaktor = x3d.get('skalierungsfaktor');

      var cords;

      var p1 = new Object();
      var p2 = new Object();

      var drehachsen = new Object();
      var drehwinkel = new Object();

      var w90Grad = 89 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var korrektur = 0.05;

      var item = x3d.get('bauteile').findBy('id', name);

      if (item.get('typ') === 'box') {

        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;

        if (name === 'traufkonstruktion1' || name === 'traufkonstruktion2') {
          if (x3d.get('bauder')) {
            x = 12;
            y = 0.6;
            z = 1;
          } else {
            x = item.get('boxsizes').objectAt(0).get('x') / 10;
          }
        } else {
          x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 10;
          if (name.substring(0, 8) === 'daemmung' && x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('traufkonstruktion') && !x3d.get('bauder')) {
            x = x - x3d.get('bauteile').findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).get('x') / 10;
          }
        }

        if (name === 'grundlatte1' && x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('grundlatteMaterial')) {
          z = x3d.get('bauteile').findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('z') / 10;
        }

        if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') === 'beton' && name === 'sparren1') {
          z = x3d.get('bauteile').findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('z') / 10;
        }

        if (typ === 'kontur') {
          cords = this.getKonturKoordinaten(x, y, z);
        }

        if (typ === 'faserrichtung') {

          skalierungsfaktor = 1;
          var delta = 0.15;

          x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
          if (item.get('boxsizes').objectAt(0).get('y') / 10 > 0.45) {
            y = 0.15;
          } else {
            delta = item.get('boxsizes').objectAt(0).get('y') / 25;
            y = item.get('boxsizes').objectAt(0).get('y') / 20 - delta;
          }

          z = item.get('boxsizes').objectAt(0).get('z') / 20;

          cords = -x + 0.15 + delta + ' ' + (y + delta * 2) + ' ' + z + ', ' + (-x + 0.15) + ' ' + (y + delta) + ' ' + z + ', ' + (-x + 1) + ' ' + (y + delta) + ' ' + z + ', ' + (-x + 0.85) + ' ' + y + ' ' + z;
        }

        if (typ === 'breite') {
          switch (name) {
            case 'grundlatte1':

              if (bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('grundlatteMaterial')) {
                z = bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('z') / 20;
              } else {
                z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
              }

              p1.x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
              p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p1.z = z;

              p2.x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
              p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p2.z = -z;

              if (x3d.get('istFassade') === true) {
                if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = 0;
                  drehwinkel.y = -w90Grad;
                  drehwinkel.z = 0;
                  p1.y = p1.y + abstandVomBauteil;
                  p2.y = p1.y;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = -w90Grad;
                  drehwinkel.z = -w90Grad;
                  p1.x = p1.x + abstandVomBauteil;
                  p2.x = p1.x;
                }
              } else {
                if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = 0;
                  drehwinkel.y = -w90Grad;
                  drehwinkel.z = 0;
                  p1.y = p1.y + abstandVomBauteil;
                  p2.y = p1.y;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = -w90Grad;
                  drehwinkel.z = -w90Grad;
                  p1.x = p1.x + abstandVomBauteil;
                  p2.x = p1.x;
                }
              }

              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'sparren2':
              p1.x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
              p1.y = 0;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
              p2.y = 0;
              p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

              if (x3d.get('istFassade') === true) {
                if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = 0;
                  drehwinkel.y = -w90Grad;
                  drehwinkel.z = -w180Grad;
                  p1.x = p1.x - abstandVomBauteil;
                  p2.x = p1.x;
                  p1.y = p1.y - abstandVomBauteil;
                  p2.y = p1.y;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = -w90Grad;
                  drehwinkel.z = w90Grad;
                  p1.x = p1.x - abstandVomBauteil;
                  p2.x = p1.x;
                }
              } else {
                if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = 0;
                  drehwinkel.y = -w90Grad;
                  drehwinkel.z = w90Grad;
                  p1.x = p1.x - abstandVomBauteil;
                  p2.x = p1.x;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = -w90Grad;
                  drehwinkel.z = w180Grad;
                  p1.y = p1.y - abstandVomBauteil;
                  p2.y = p1.y;
                }
              }

              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
          }
        }

        if (typ === 'hoehe') {

          switch (name) {
            case 'grundlatte1':

              if (bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('grundlatteMaterial')) {
                z = bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('z') / 20;
              } else {
                z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
              }

              p1.x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
              p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p1.z = -z;

              p2.x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
              p2.y = 0;
              p2.z = -z;

              if (x3d.get('istFassade') === true) {
                if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = -w90Grad;
                  drehwinkel.y = 0;
                  drehwinkel.z = w90Grad;
                  p1.z = p1.z - abstandVomBauteil - 1.15;
                  p2.z = p1.z;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = 0;
                  drehwinkel.z = w90Grad;
                  p1.x = p1.x - abstandVomBauteil;
                  p2.x = p1.x;
                }
              } else {
                if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = -w90Grad;
                  drehwinkel.y = 0;
                  drehwinkel.z = w90Grad;
                  p1.z = p1.z - abstandVomBauteil - 1.15;
                  p2.z = p1.z;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = 0;
                  drehwinkel.z = w90Grad;
                  p1.x = p1.x - abstandVomBauteil;
                  p2.x = p1.x;
                }
              }

              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'sparren1':
              p1.x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
              p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p1.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

              p2.x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
              p2.y = 0;
              p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

              if (x3d.get('istFassade') === true) {
                if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = -w90Grad;
                  drehwinkel.y = 0;
                  drehwinkel.z = w90Grad;
                  p1.z = p1.z - abstandVomBauteil;
                  p2.z = p1.z;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = 0;
                  drehwinkel.z = w90Grad;
                  p1.x = p1.x - abstandVomBauteil;
                  p2.x = p1.x;
                }
              } else {
                if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = -w90Grad;
                  drehwinkel.y = 0;
                  drehwinkel.z = w90Grad;
                  p1.z = p1.z - abstandVomBauteil;
                  p2.z = p1.z;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = 0;
                  drehwinkel.z = w90Grad;
                  p1.x = p1.x - abstandVomBauteil;
                  p2.x = p1.x;
                }
              }

              if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') === 'beton') {
                p1.z = -(x3d.get('bauteile').findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('z') / 20);
                p2.z = p1.z;
              }

              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'daemmung1':
              var xVerschiebung = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
              if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('traufkonstruktion') && !x3d.get('bauder')) {
                xVerschiebung = xVerschiebung - x3d.get('bauteile').findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).get('x') / 20;
              }

              p1.x = xVerschiebung;
              p1.y = 0;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = xVerschiebung;
              p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              if (x3d.get('istFassade') === true) {
                if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = w90Grad;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.z = p1.z + abstandVomBauteil;
                  p2.z = p1.z;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.x = p1.x + abstandVomBauteil;
                  p2.x = p1.x;
                }
              } else {
                if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = w90Grad;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.z = p1.z + abstandVomBauteil;
                  p2.z = p1.z;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.x = p1.x + abstandVomBauteil;
                  p2.x = p1.x;
                }
              }

              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'daemmung2':
              var xVerschiebung = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
              if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('traufkonstruktion')) {
                xVerschiebung = xVerschiebung - x3d.get('bauteile').findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).get('x') / 20;
              }

              p1.x = xVerschiebung;
              p1.y = 0;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = xVerschiebung;
              p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              if (x3d.get('istFassade') === true) {
                if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = w90Grad;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.z = p1.z + abstandVomBauteil;
                  p2.z = p1.z;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.x = p1.x + abstandVomBauteil;
                  p2.x = p1.x;
                }
              } else {
                if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = w90Grad;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.z = p1.z + abstandVomBauteil;
                  p2.z = p1.z;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.x = p1.x + abstandVomBauteil;
                  p2.x = p1.x;
                }
              }

              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'schalung':
              p1.x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
              p1.y = 0;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
              p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              if (x3d.get('istFassade') === true) {
                if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = w90Grad;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.z = p1.z + abstandVomBauteil;
                  p2.z = p1.z;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.x = p1.x + abstandVomBauteil;
                  p2.x = p1.x;
                }
              } else {
                if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                  drehwinkel.x = w90Grad;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.z = p1.z + abstandVomBauteil;
                  p2.z = p1.z;
                } else {
                  drehwinkel.x = 0;
                  drehwinkel.y = 0;
                  drehwinkel.z = -w90Grad;
                  p1.x = p1.x + abstandVomBauteil;
                  p2.x = p1.x;
                }
              }

              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
          }
        }
      }

      if (typ === 'lastenPfeil') {
        cords = '0 0 0 0 1 0';
      }

      if (name === 'dachgeometrie') {
        var skalierung = this.objektSkalierung;

        var gebaeudebreite = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / skalierung;
        var gebaeudelaenge = item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / skalierung;
        var firsthoehe = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / skalierung;
        var dachneigung = item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180;
        var dachneigung2 = item.get('gebaeudemasse').objectAt(0).get('dachneigung2') * Math.PI / 180;
        var firstueberstand = item.get('gebaeudemasse').objectAt(0).get('firstueberstand') / skalierung;
        var traufeueberstand = item.get('gebaeudemasse').objectAt(0).get('traufeueberstand') / skalierung;
        var giebelueberstand = item.get('gebaeudemasse').objectAt(0).get('giebelueberstand') / skalierung;

        switch (typ) {
          case 'sparrenabstand':
            p1.x = -(item.get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
            p1.y = 0;

            p2.x = -(item.get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
            p2.y = 0;

            if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') === 'beton') {
              p1.z = item.get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
              p2.z = -(item.get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20);
            } else {
              p1.z = -(bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('z') / 20) + item.get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
              p2.z = -(bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('z') / 20) - item.get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 20;
            }

            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = 0;
                drehwinkel.y = -w90Grad;
                drehwinkel.z = w180Grad;
                p1.y = p1.y - abstandVomBauteil;
                p2.y = p1.y;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = -w90Grad;
                drehwinkel.z = w90Grad;
                p1.x = p1.x - abstandVomBauteil;
                p2.x = p1.x;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = 0;
                drehwinkel.y = -w90Grad;
                drehwinkel.z = w90Grad;
                p1.x = p1.x - abstandVomBauteil;
                p2.x = p1.x;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = -w90Grad;
                drehwinkel.z = w180Grad;
                p1.y = p1.y - abstandVomBauteil;
                p2.y = p1.y;
              }
            }
            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'breite':
            p1.x = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2);
            p1.y = -abstandVomBauteil;
            p1.z = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2;

            p2.x = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2);
            p2.y = -abstandVomBauteil;
            p2.z = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2);

            drehwinkel.x = 0;
            drehwinkel.y = -w90Grad;
            drehwinkel.z = w180Grad;

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'laenge':
            p1.x = item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2;
            p1.y = -abstandVomBauteil;
            p1.z = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2;

            p2.x = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2);
            p2.y = -abstandVomBauteil;
            p2.z = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2;

            drehwinkel.x = w180Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'hoehe':
            p1.x = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2) - abstandVomBauteil;
            p1.y = Number(item.get('gebaeudemasse').objectAt(0).get('firsthoehe')) + 0.2;
            p1.z = 0;

            p2.x = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2) - abstandVomBauteil;
            p2.y = 0;
            p2.z = 0;

            if (item.get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'pultdach') {
              p1.z = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2);
              p2.z = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2);
            }

            drehwinkel.x = 0;
            drehwinkel.y = 0;
            drehwinkel.z = w90Grad;

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'giebelueberstand':
            p1.x = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2);
            p1.y = Number(item.get('gebaeudemasse').objectAt(0).get('firsthoehe'));
            p1.z = 0;

            p2.x = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2) - item.get('gebaeudemasse').objectAt(0).get('giebelueberstand');
            p2.y = Number(item.get('gebaeudemasse').objectAt(0).get('firsthoehe'));
            p2.z = 0;

            if (item.get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'pultdach') {
              p1.y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2 * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180);
              p2.y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2 * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180);
            }

            if (item.get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'walmdach') {
              var gebBreite = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite');
              var giebelUeberstand = item.get('gebaeudemasse').objectAt(0).get('giebelueberstand');
              var dn1 = item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180;
              var dn2 = item.get('gebaeudemasse').objectAt(0).get('dachneigung2') * Math.PI / 180;

              p1.y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - gebBreite / 2 * Math.tan(dn2);
              p2.y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - gebBreite / 2 * Math.tan(dn2);
            }

            p1.y = p1.y + 0.2 + abstandVomBauteil;
            p2.y = p2.y + 0.2 + abstandVomBauteil;

            drehwinkel.x = 0;
            drehwinkel.y = 0;
            drehwinkel.z = 0;

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);

            break;
          case 'traufeueberstand':
            p1.x = 0;
            p1.y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2 * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180);
            p1.z = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2;

            p2.x = 0;
            p2.z = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2 + Number(item.get('gebaeudemasse').objectAt(0).get('traufeueberstand'));

            var gebBreite = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite');
            var dn1 = item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180;
            var dn2 = item.get('gebaeudemasse').objectAt(0).get('dachneigung2') * Math.PI / 180;

            if (item.get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'pultdach') {
              p1.y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - gebBreite * Math.tan(dn1);
            }

            if (item.get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'walmdach') {
              p1.y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - gebBreite / 2 * Math.tan(dn2);
            }

            p1.y = p1.y + 0.2 + abstandVomBauteil;
            p2.y = p1.y;

            drehwinkel.x = 0;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);

            break;
          case 'firstueberstand':
            p1.x = 0;
            p1.y = Number(item.get('gebaeudemasse').objectAt(0).get('firsthoehe')) + item.get('gebaeudemasse').objectAt(0).get('firstueberstand') * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180) + 0.2 + abstandVomBauteil;
            p1.z = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2) - Number(item.get('gebaeudemasse').objectAt(0).get('firstueberstand'));

            p2.x = 0;
            p2.y = Number(item.get('gebaeudemasse').objectAt(0).get('firsthoehe')) + Number(item.get('gebaeudemasse').objectAt(0).get('firstueberstand')) * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180) + 0.2 + abstandVomBauteil;
            p2.z = -(item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2);

            drehwinkel.x = 0;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);

            break;
          case 'giebellaenge':

            var laenge = item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge');
            var dn1 = item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180;
            var dn2 = item.get('gebaeudemasse').objectAt(0).get('dachneigung2') * Math.PI / 180;
            var breite = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite');

            p1.x = laenge / 2 - breite / 2 * Math.tan(dn2) / Math.tan(dn1);
            p1.y = Number(item.get('gebaeudemasse').objectAt(0).get('firsthoehe')) + 0.2 + abstandVomBauteil;
            p1.z = 0;

            p2.x = -(laenge / 2) + breite / 2 * Math.tan(dn2) / Math.tan(dn1);
            p2.y = Number(item.get('gebaeudemasse').objectAt(0).get('firsthoehe')) + 0.2 + abstandVomBauteil;
            p2.z = 0;

            drehwinkel.x = 0;
            drehwinkel.y = 0;
            drehwinkel.z = 0;

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'satteldach':
            cords = this.getSatteldachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, firstueberstand, traufeueberstand, giebelueberstand);
            break;
          case 'pultdach':
            cords = this.getPultdachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, firstueberstand, traufeueberstand, giebelueberstand);
            break;
          case 'walmdach':
            cords = this.getWalmdachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, dachneigung2, firstueberstand, traufeueberstand, giebelueberstand);
            break;
          default:
        }
      }
      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.viewpointHelper'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === 'box') {

        if (typ === 'kontur') {
          values = "0 1 2 3 0 -1 4 5 6 7 4 -1 0 4 -1 1 5 -1 2 6 -1 3 7";
        }

        if (typ === 'faserrichtung') {
          values = "0 1 -1 1 2 -1 2 3 -1";
        }
      } else {
        if (typ === 'lastenPfeil') {
          values = "0 1 -1";
        }

        if (typ === 'hWinkel') {
          values = "0 1 -1 0 2 -1";
        }
        if (typ === 'vWinkel') {
          values = "0 1 -1 0 2 -1";
        }

        if (name.substring(0, 8) === 'schraube' && x3d.objectAt(0).get('ergebnisGeladen')) {
          if (typ === 'xPos' && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else if (typ === 'zPos' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else {
            values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
          }
        }

        if (name === 'dachgeometrie') {
          switch (typ) {
            case 'satteldach':
              values = "0 1 2 3 0 -1 0 4 -1 1 5 -1 2 6 -1 3 7 -1 8 9 10 11 12 13 8 -1 14 15 16 17 18 19 14 -1 9 15 -1 10 16 -1 12 18 -1 13 19 -1";
              break;
            case 'pultdach':
              values = "0 1 2 3 0 -1 0 4 -1 1 5 -1 2 6 -1 3 7 -1 8 9 10 11 8 -1 12 13 14 15 12 -1 8 12 -1 9 13 -1 10 14 -1 11 15 -1";
              break;
            case 'walmdach':
              values = "0 1 2 3 0 -1 0 4 -1 1 5 -1 2 6 -1 3 7 -1 4 5 6 7 4 -1 8 9 10 11 8 -1 8 23 20 8 -1 11 21 22 11 -1 8 11 12 13 8 -1 18 27 24 18 -1 18 14 15 19 18 -1 19 25 26 19 -1 19 16 17 18 19 -1 20 24 -1 9 14 -1 10 15 -1 12 16 -1 13 17 -1 21 25 -1 22 26 -1 23 27 -1";
              break;
            default:
              values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
          }
        }
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    istLastenpfeil: (function () {
      var istLastenpfeil = false;

      if (this.get('name') === 'lastenPfeil') {
        istLastenpfeil = true;
      }
      return istLastenpfeil;
    }).property(''),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('sphoeheAktiv') === true && name === 'sparren1' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('spbreiteAktiv') === true && name === 'sparren2' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('schalungshoeheAktiv') === true && name === 'schalung' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('daemmstoffDickeAktiv') === true && name.substring(0, 8) === 'daemmung' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('glhoeheAktiv') === true && name === 'grundlatte1' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('glbreiteAktiv') === true && name === 'grundlatte1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('spabstandAktiv') === true && name === 'dachgeometrie') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('firsthoeheAktiv') === true && name === 'dachgeometrie' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('dneigungAktiv') === true && name === 'dachgeometrie' && typ === 'dachneigung') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('dneigung2Aktiv') === true && name === 'dachgeometrie' && typ === 'dachneigung2') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('gbreiteAktiv') === true && name === 'dachgeometrie' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('glaengeAktiv') === true && name === 'dachgeometrie' && typ === 'laenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('uegiebelAktiv') === true && name === 'dachgeometrie' && typ === 'giebelueberstand') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('uetraufeAktiv') === true && name === 'dachgeometrie' && typ === 'traufeueberstand') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('uefirstAktiv') === true && name === 'dachgeometrie' && typ === 'firstueberstand') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.spbreiteAktiv', 'model.firstObject.sphoeheAktiv', 'model.firstObject.glbreiteAktiv', 'model.firstObject.glhoeheAktiv', 'model.firstObject.spabstandAktiv', 'model.firstObject.daemmstoffDickeAktiv', 'model.firstObject.schalungshoeheAktiv', 'model.firstObject.uefirstAktiv', 'model.firstObject.uetraufeAktiv', 'model.firstObject.uegiebelAktiv', 'model.firstObject.glaengeAktiv', 'model.firstObject.gbreiteAktiv', 'model.firstObject.dneigung2Aktiv', 'model.firstObject.dneigungAktiv', 'model.firstObject.firsthoeheAktiv'),

    getSatteldachKoordinaten: function getSatteldachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, firstueberstand, traufeueberstand, giebelueberstand) {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20, p21;
      var x, y, deltaYTraufe, z;

      y = firsthoehe - Math.tan(dachneigung) * gebaeudebreite / 2;
      deltaYTraufe = Math.tan(dachneigung) * traufeueberstand;

      p0 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p1 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p2 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p3 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p4 = -(gebaeudelaenge / 2) + ' ' + y + ' ' + -(gebaeudebreite / 2);
      p5 = -(gebaeudelaenge / 2) + ' ' + y + ' ' + gebaeudebreite / 2;
      p6 = gebaeudelaenge / 2 + ' ' + y + ' ' + gebaeudebreite / 2;
      p7 = gebaeudelaenge / 2 + ' ' + y + ' ' + -(gebaeudebreite / 2);
      p8 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + firsthoehe + ' ' + 0;
      p9 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p10 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p11 = gebaeudelaenge / 2 + giebelueberstand + ' ' + firsthoehe + ' ' + 0;
      p12 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p13 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p14 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (firsthoehe + 0.2) + ' ' + 0;
      p15 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p16 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p17 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (firsthoehe + 0.2) + ' ' + 0;
      p18 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe + 0.2) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p19 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe + 0.2) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p20 = -(gebaeudelaenge / 2) + ' ' + firsthoehe + ' ' + 0;
      p21 = -(gebaeudelaenge / 2) + ' ' + firsthoehe + ' ' + 0;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15 + ', ' + p16 + ', ' + p17 + ', ' + p18 + ', ' + p19;
      return koordinaten;
    },

    getPultdachKoordinaten: function getPultdachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, firstueberstand, traufeueberstand, giebelueberstand) {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15;
      var x, y, deltaYTraufe, deltaYFirst, z;

      y = firsthoehe - Math.tan(dachneigung) * gebaeudebreite;
      deltaYTraufe = Math.tan(dachneigung) * traufeueberstand;
      deltaYFirst = Math.tan(dachneigung) * firstueberstand;

      p0 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p1 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p2 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p3 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p4 = -(gebaeudelaenge / 2) + ' ' + firsthoehe + ' ' + -(gebaeudebreite / 2);
      p5 = -(gebaeudelaenge / 2) + ' ' + y + ' ' + gebaeudebreite / 2;
      p6 = gebaeudelaenge / 2 + ' ' + y + ' ' + gebaeudebreite / 2;
      p7 = gebaeudelaenge / 2 + ' ' + firsthoehe + ' ' + -(gebaeudebreite / 2);

      p8 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (firsthoehe + deltaYFirst) + ' ' + -(gebaeudebreite / 2 + firstueberstand);
      p9 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p10 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p11 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (firsthoehe + deltaYFirst) + ' ' + -(gebaeudebreite / 2 + firstueberstand);

      p12 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (firsthoehe + deltaYFirst + 0.2) + ' ' + -(gebaeudebreite / 2 + firstueberstand);
      p13 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p14 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p15 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (firsthoehe + deltaYFirst + 0.2) + ' ' + -(gebaeudebreite / 2 + firstueberstand);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15;
      return koordinaten;
    },

    getWalmdachKoordinaten: function getWalmdachKoordinaten(gebaeudebreite, gebaeudelaenge, firsthoehe, dachneigung, dachneigung2, firstueberstand, traufeueberstand, giebelueberstand) {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20, p21, p22, p23, p24, p25, p26, p27;
      var x, y, z;
      y = firsthoehe - Math.tan(dachneigung2) * gebaeudebreite / 2;
      var deltaYGiebel = Math.tan(dachneigung) * giebelueberstand;
      var deltaZGiebel = deltaYGiebel / Math.tan(dachneigung2);
      var deltaYTraufe = Math.tan(dachneigung2) * traufeueberstand;
      var deltaXTraufe = deltaYTraufe / Math.tan(dachneigung);
      var deltaX = Math.tan(dachneigung2) * gebaeudebreite / 2 / Math.tan(dachneigung);

      p0 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p1 = -(gebaeudelaenge / 2) + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p2 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + gebaeudebreite / 2;
      p3 = gebaeudelaenge / 2 + ' ' + 0 + ' ' + -(gebaeudebreite / 2);
      p4 = -(gebaeudelaenge / 2) + ' ' + y + ' ' + -(gebaeudebreite / 2);
      p5 = -(gebaeudelaenge / 2) + ' ' + y + ' ' + gebaeudebreite / 2;
      p6 = gebaeudelaenge / 2 + ' ' + y + ' ' + gebaeudebreite / 2;
      p7 = gebaeudelaenge / 2 + ' ' + y + ' ' + -(gebaeudebreite / 2);

      p8 = -gebaeudelaenge / 2 + deltaX + ' ' + firsthoehe + ' ' + 0;
      p9 = -(gebaeudelaenge / 2 + deltaXTraufe) + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p10 = gebaeudelaenge / 2 + deltaXTraufe + ' ' + (y - deltaYTraufe) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p11 = gebaeudelaenge / 2 - deltaX + ' ' + firsthoehe + ' ' + 0;

      p12 = gebaeudelaenge / 2 + deltaXTraufe + ' ' + (y - deltaYTraufe) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p13 = -(gebaeudelaenge / 2 + deltaXTraufe) + ' ' + (y - deltaYTraufe) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p14 = -(gebaeudelaenge / 2 + deltaXTraufe) + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p15 = gebaeudelaenge / 2 + deltaXTraufe + ' ' + (y - deltaYTraufe + 0.2) + ' ' + (gebaeudebreite / 2 + traufeueberstand);
      p16 = gebaeudelaenge / 2 + deltaXTraufe + ' ' + (y - deltaYTraufe + 0.2) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);
      p17 = -(gebaeudelaenge / 2 + deltaXTraufe) + ' ' + (y - deltaYTraufe + 0.2) + ' ' + -(gebaeudebreite / 2 + traufeueberstand);

      p18 = -(gebaeudelaenge / 2 - deltaX) + ' ' + (firsthoehe + 0.2) + ' ' + 0;
      p19 = gebaeudelaenge / 2 - deltaX + ' ' + (firsthoehe + 0.2) + ' ' + 0;

      p20 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYGiebel) + ' ' + (gebaeudebreite / 2 + deltaZGiebel);
      p21 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYGiebel) + ' ' + (gebaeudebreite / 2 + deltaZGiebel);
      p22 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYGiebel) + ' ' + -(gebaeudebreite / 2 + deltaZGiebel);
      p23 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYGiebel) + ' ' + -(gebaeudebreite / 2 + deltaZGiebel);
      p24 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYGiebel + 0.2) + ' ' + (gebaeudebreite / 2 + deltaZGiebel);
      p25 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYGiebel + 0.2) + ' ' + (gebaeudebreite / 2 + deltaZGiebel);
      p26 = gebaeudelaenge / 2 + giebelueberstand + ' ' + (y - deltaYGiebel + 0.2) + ' ' + -(gebaeudebreite / 2 + deltaZGiebel);
      p27 = -(gebaeudelaenge / 2 + giebelueberstand) + ' ' + (y - deltaYGiebel + 0.2) + ' ' + -(gebaeudebreite / 2 + deltaZGiebel);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15 + ', ' + p16 + ', ' + p17 + ', ' + p18 + ', ' + p19 + ', ' + p20 + ', ' + p21 + ', ' + p22 + ', ' + p23 + ', ' + p24 + ', ' + p25 + ', ' + p26 + ', ' + p27;

      return koordinaten;
    },

    getUrsprungMasskette: function getUrsprungMasskette(ursprung, richtung, verschiebung) {

      ursprung.x = ursprung.x + richtung.x * verschiebung;
      ursprung.y = ursprung.y + richtung.y * verschiebung;
      ursprung.z = ursprung.z + richtung.z * verschiebung;

      return ursprung;
    },

    getKonturKoordinaten: function getKonturKoordinaten(x, y, z) {

      var p0 = -x / 2 + ' ' + 0 + ' ' + -z / 2;
      var p1 = -x / 2 + ' ' + 0 + ' ' + z / 2;
      var p2 = x / 2 + ' 0 ' + z / 2;
      var p3 = x / 2 + ' 0 ' + -z / 2;
      var p4 = -x / 2 + ' ' + y + ' ' + -z / 2;
      var p5 = -x / 2 + ' ' + y + ' ' + z / 2;
      var p6 = x / 2 + ' ' + y + ' ' + z / 2;
      var p7 = x / 2 + ' ' + y + ' ' + -z / 2;
      var p8 = 0;
      var p9 = 0;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    getMassketteKoordinaten: function getMassketteKoordinaten(punkt1, punkt2, drehachsen, drehwinkel) {

      var name = this.get('name');
      var typ = this.get('typ');

      // var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor')+1;
      var skalierungsfaktor = 1;

      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;

      var hwinkel = Number(this.get('model').objectAt(0).get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(this.get('model').objectAt(0).get('vWinkel')) * Math.PI / 180;

      var dp = new Object();
      var deltaX = 0;
      var deltaY = 0;

      if (name === 'dachgeometrie' && typ === 'hoehe') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaX = -item.get('gebaeudemasse').objectAt(0).get('giebelueberstand');
      }

      if (name === 'dachgeometrie' && typ === 'traufeueberstand') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaY = -Number(item.get('gebaeudemasse').objectAt(0).get('traufeueberstand')) * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung2') * Math.PI / 180);
      }

      if (name === 'dachgeometrie' && typ === 'firstueberstand') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaY = -Number(item.get('gebaeudemasse').objectAt(0).get('firstueberstand')) * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180);
      }

      if (name === 'dachgeometrie' && typ === 'giebelueberstand' && this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'walmdach') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaY = -Number(item.get('gebaeudemasse').objectAt(0).get('giebelueberstand')) * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180);
      }

      var p0 = Number(punkt1.x) + ' ' + punkt1.y + ' ' + punkt1.z;
      var p1 = Number(punkt2.x) + ' ' + (Number(punkt2.y) + Number(deltaY)) + ' ' + punkt2.z;

      dp = this.drehungXAchse({ x: 0, y: b6, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p2 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var p3 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p4 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: -b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p5 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: -b05, y: b45, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p6 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p8 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: b05, y: b55, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p7 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p9 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    delta: function delta(l, winkel) {
      var value = l * Math.cos(winkel);
      return value;
    },

    vektorLaenge: function vektorLaenge(x, y, z) {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return skalierungsfaktor * Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    },

    winkel: function winkel(x, y) {
      return Math.atan(x / y);
    },

    vektorenWinkel: function vektorenWinkel(v1, v2) {
      var zaehler = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
      // console.log('zaehler: '+zaehler);
      var n1 = Math.sqrt(Math.pow(v1.x, 2) + Math.pow(v1.y, 2) + Math.pow(v1.z, 2));
      // console.log('n1: '+n1);
      var n2 = Math.sqrt(Math.pow(v2.x, 2) + Math.pow(v2.y, 2) + Math.pow(v2.z, 2));
      // console.log('n2: '+n2);
      var nenner = n1 * n2;
      // console.log('nenner: '+nenner);
      // console.log('zaehler/nenner: '+zaehler/nenner);
      var alpha = Math.acos((zaehler / nenner).toFixed(6));
      return alpha;
    },

    differenzVektor: function differenzVektor(v2, v1) {
      var vResult = new Object();
      vResult.x = v2.x - v1.x;
      vResult.y = v2.y - v1.y;
      vResult.z = v2.z - v1.z;
      return vResult;
    },

    drehungXAchse: function drehungXAchse(v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungYAchse: function drehungYAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungZAchse: function drehungZAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    }

  });

  exports['default'] = Transform;

});