define('m01new/controllers/unterkonstruktion', ['exports', 'ember', 'ember-validations'], function (exports, Ember, EmberValidations) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    master: true,
    istBetonUnterkonstruktion: false,

    unterkonstruktion: 0,

    openFromFile: false,
    displayErrors: true,

    istFassade: false,

    validations: {
      schalung: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 5
        }
      },
      spAbstand: {
        numericality: {
          greaterThanOrEqualTo: 25,
          lessThanOrEqualTo: 250
        }
      },
      spHoehe: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 9999.9
        }
      },
      spBreite: {
        numericality: {
          greaterThanOrEqualTo: 3.6,
          lessThanOrEqualTo: 250
        }
      }
    },

    ttSchalung: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 5 [cm]";
    }),

    ttSpAbstand: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 25 - 250 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    ttSpHoehe: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 5 - 9999.9 [cm]";
    }),
    ttSpBreite: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 3.6 - 250 [cm]";
    }),
    i18n: Ember['default'].inject.service(),

    schalungNotSelected: true,

    ukWuerth: [{
      name: "auf_sparren",
      id: 0
    }, {
      name: "auf_schalung",
      id: 1
    }
    // ,{
    //   name: "auf_beton",
    //   id: 2
    // }, {
    //   name: "auf_porenbeton",
    //   id: 3
    // }
    ],

    ukWuerthAlternativ: [{
      name: "auf_rippen",
      id: 0
    }, {
      name: "auf_schalung",
      id: 1
    }],

    ukBauder: [{
      name: "auf_sparren",
      id: 0
    }, {
      name: "auf_schalung",
      id: 1
    }, {
      name: "auf_beton",
      id: 2
    }],

    ukContent: [],

    spmaterial: [{
      name: "Vollholz Nadelholz",
      ind: 0
    }, {
      name: "Brettschichtholz homogen",
      ind: 1
    }, {
      name: "Brettschichtholz kombiniert",
      ind: 13
    }],

    fkl_vh: [{
      fklind: 0,
      fkl: "C14"
    }, {
      fklind: 1,
      fkl: "C16"
    }, {
      fklind: 2,
      fkl: "C18"
    }, {
      fklind: 3,
      fkl: "C20"
    }, {
      fklind: 4,
      fkl: "C22"
    }, {
      fklind: 5,
      fkl: "C24"
    }, {
      fklind: 6,
      fkl: "C27"
    }, {
      fklind: 7,
      fkl: "C30"
    }],

    fkl_bsh: [{
      fklind: 20,
      fkl: "GL24h"
    }, {
      fklind: 21,
      fkl: "GL28h"
    }, {
      fklind: 24,
      fkl: "GL30h"
    }, {
      fklind: 22,
      fkl: "GL32h"
    }],

    fkl_bsh_kombi: [{
      fklind: 30,
      fkl: "GL24c"
    }, {
      fklind: 31,
      fkl: "GL28c"
    }, {
      fklind: 34,
      fkl: "GL30c"
    }, {
      fklind: 32,
      fkl: "GL32c"
    }],

    spfklarray: [],
    spfkl: 5,
    sparrenmaterial: 0,

    schalung: "0.0",
    spAbstand: "",
    spBreite: "",
    spHoehe: "",

    init: function init() {
      this._super();
      this.set('spfklarray', this.fkl_vh);

      var self = this;

      var application = this.controllerFor('application');

      this.set('sparrenmaterial', 0);
      this.set('spfkl', 5);

      if (application.get('bauder')) {
        if (application.get('bauderMaster')) {
          this.set('master', true);
          // this.validations.spHoehe.numericality: {
          //     greaterThanOrEqualTo: 5,
          this.validations.spHoehe.numericality.greaterThanOrEqualTo = 4.2;
          this.validations.spBreite.numericality.greaterThanOrEqualTo = 4;
          this.set('ttSpHoehe', this.get('i18n').t('wertebereich') + " 4.2 - 9999.9 [cm]");
          this.set('ttSpBreite', this.get('i18n').t('wertebereich') + " 4 - 250 [cm]");
        } else {
          this.set('master', false);
          this.validations.spHoehe.numericality.greaterThanOrEqualTo = 6;
          this.validations.spBreite.numericality.greaterThanOrEqualTo = 6;
          this.set('ttSpHoehe', this.get('i18n').t('wertebereich') + " 6 - 9999.9 [cm]");
          this.set('ttSpBreite', this.get('i18n').t('wertebereich') + " 6 - 250 [cm]");
        }
        this.setHoeheUnterkonstruktion();
        this.setSchraubenEigenschaften();
      } else {
        this.set('master', true);
      }

      self.setSelectFieldsContent();
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.get('debug') === true) {
        console.log("values in unterkonstruktion: ");
        console.log(values);
      }

      this.set('openFromFile', true);

      this.set('unterkonstruktion', parseInt(values.UK_ind));
      this.set('openFromFile', false);
      this.watchSchalung();
      this.watchComboBoxes();
      this.set('openFromFile', true);

      this.set('schalung', parseFloat(values.tUK1).toFixed(1));
      this.setX3D(parseFloat(values.tUK1), { target: { name: "schalung" } });

      this.set('sparrenmaterial', parseInt(values.MatKeyTimberElementSp));
      this.set('spfkl', parseInt(values.FKLKeyTimberElementSp));

      this.set('spAbstand', parseInt(values.e_Sp).toFixed(1));
      this.setX3D(parseFloat(values.e_Sp), { target: { name: "spAbstand" } });

      this.set('spHoehe', parseInt(values.TimberElementSp_h).toFixed(1));
      this.setX3D(parseFloat(values.TimberElementSp_h), { target: { name: "spHoehe" } });

      this.set('spBreite', parseInt(values.TimberElementSp_b).toFixed(1));
      this.setX3D(parseFloat(values.TimberElementSp_b), { target: { name: "spBreite" } });

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setUnterkonstruktionen();
      self.setSpMaterialien();
      self.setSpFKL();
    },

    setUnterkonstruktionen: function setUnterkonstruktionen() {
      var self = this;
      var indices = [0, 1, 2];
      var application = this.controllerFor('application');

      if (application.get('bauder')) {
        self.set('ukContent', self.getSelectFieldContent('ukBauder', indices));
      } else {
        indices = [0, 1];
        if (self.get('istFassade') === false) {
          self.set('ukContent', self.getSelectFieldContent('ukWuerth', indices));
        } else {
          self.set('ukContent', self.getSelectFieldContent('ukWuerthAlternativ', indices));
        }
      }
    },

    setSpMaterialien: function setSpMaterialien() {
      var self = this;
      var indices = [0, 1, 13];
      self.set('spmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setSpFKL: function setSpFKL(indices) {
      var self = this;
      var indices = [];

      if (this.get('sparrenmaterial') === 1) {
        indices = [20, 21, 24, 22];
        self.set('spfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('spfkl', 20);
      } else if (self.get('sparrenmaterial') === 13) {
        indices = [30, 31, 34, 32];
        self.set('spfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('spfkl', 30);
      } else if (self.get('sparrenmaterial') === 0) {
        indices = [0, 1, 2, 3, 4, 5, 6, 7];
        self.set('spfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('spfkl', 5);
      }
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      var self = this;
      switch (db) {
        case 'ukBauder':
          dbContent = self.get('ukBauder');
          break;
        case 'ukWuerth':
          dbContent = self.get('ukWuerth');
          break;
        case 'ukWuerthAlternativ':
          dbContent = self.get('ukWuerthAlternativ');
          break;
        case 'materialienDB':
          dbContent = materialienDB.materialien_de_m03;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('schalung').indexOf(",") !== -1) {
          this.set('schalung', this.get('schalung').replace(",", "."));
        }

        if (this.get('spAbstand').indexOf(",") !== -1) {
          this.set('spAbstand', this.get('spAbstand').replace(",", "."));
        }

        if (this.get('spHoehe').indexOf(",") !== -1) {
          this.set('spHoehe', this.get('spHoehe').replace(",", "."));
        }

        if (this.get('spBreite').indexOf(",") !== -1) {
          this.set('spBreite', this.get('spBreite').replace(",", "."));
        }
      }
    }).observes('schalung', 'spAbstand', 'spHoehe', 'spBreite'),

    watchSpMaterial: (function () {

      var self = this;
      // console.log(this.title);
      // if (this.get('sparrenmaterial') === 1) {
      //   this.set('spfklarray', this.fkl_bsh);
      //   this.set('spfkl', 20);
      // } else if (this.get('sparrenmaterial') === 13) {
      //   this.set('spfklarray', this.fkl_bsh_kombi);
      //   this.set('spfkl', 30);
      // } else {
      //   this.set('spfklarray', this.fkl_vh);
      //   this.set('spfkl', 5);
      // }

      this.setSpFKL();
    }).observes('sparrenmaterial'),

    watchSchalung: (function () {

      if (!this.openFromFile) {

        if (this.get('unterkonstruktion') === 1) {
          this.set('schalungNotSelected', false);
          this.set('schalung', '');
        } else {
          this.set('schalung', '0.0');
          var application = this.controllerFor('application');
          var x3d = application.get('model').x3ddefault.objectAt(0);
          var bauteile = x3d.get('bauteile');
          bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).set('y', 0);
          x3d.set('schalungshoeheEingetragen', false);
          this.setHoeheUnterkonstruktion();
          this.setSchraubenEigenschaften();
          this.set('schalungNotSelected', true);
        }
        this.set('openFromFile', true);
        this.send('validation');
        this.set('openFromFile', false);
      }
    }).observes('unterkonstruktion'),

    watchComboBoxes: (function () {

      var self = this;

      if (!this.openFromFile) {

        var application = self.controllerFor('application');
        var unterkonstruktionData = self.controllerFor('application').get('model').unterkonstruktion.objectAt(0);
        var daemmungData = self.controllerFor('application').get('model').daemmung.objectAt(0);
        var daemmung = self.controllerFor('daemmung');

        unterkonstruktionData.set('UK_ind', this.get('unterkonstruktion'));
        unterkonstruktionData.set('MatKeyTimberElementSp', this.get('sparrenmaterial'));
        unterkonstruktionData.set('FKLKeyTimberElementSp', this.get('spfkl'));

        var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
        var gebaeudemasse = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0);

        this.resetDaemmstoff();

        if (this.get('unterkonstruktion') === 0) {
          this.set('schalungNotSelected', true);
          gebaeudemasse.set('schalung', '');
          daemmungData.set('SelectedVarId', "0");
          gebaeudemasse.set('traufkonstruktion', 'false');

          self.set('istBetonUnterkonstruktion', false);

          if (daemmung.get('daemmstoffdruckfest_1') === true) {
            x3d.set('aktuellerSchraubenTyp', 'schraube2');
          } else {
            x3d.set('aktuellerSchraubenTyp', 'schraube1');
          }

          gebaeudemasse.set('traufkonstruktion', false);
        } else if (this.get('unterkonstruktion') === 1) {
          this.set('schalungNotSelected', false);
          daemmungData.set('SelectedVarId', "0");
          gebaeudemasse.set('schalung', 'holz');

          self.set('istBetonUnterkonstruktion', false);

          if (daemmung.get('daemmstoffdruckfest_1') === true) {
            x3d.set('aktuellerSchraubenTyp', 'schraube2');
          } else {
            x3d.set('aktuellerSchraubenTyp', 'schraube1');
          }

          x3d.set('aktuellerSchraubenTyp', 'schraube2');
          gebaeudemasse.set('traufkonstruktion', false);
        } else if (this.get('unterkonstruktion') === 2) {
          this.set('schalungNotSelected', true);
          daemmungData.set('SelectedVarId', "4");
          gebaeudemasse.set('schalung', 'beton');

          self.set('istBetonUnterkonstruktion', true);

          if (x3d.get('bauder')) {
            x3d.set('aktuellerSchraubenTyp', 'schraube4');
            self.wennBauderBetonUnterkonstruktion();
            console.log('### wennBauderBetonUnterkonstruktion ###');
            console.log(x3d.get('aktuellerSchraubenTyp'));
          } else {
            x3d.set('aktuellerSchraubenTyp', 'schraube3');
          }

          gebaeudemasse.set('traufkonstruktion', true);
        } else if (this.get('unterkonstruktion') === 3) {
          this.set('schalungNotSelected', true);
          daemmungData.set('SelectedVarId', "0");
          gebaeudemasse.set('schalung', 'porenbeton');

          self.set('istBetonUnterkonstruktion', false);

          if (daemmung.get('daemmstoffdruckfest_1') === true) {
            x3d.set('aktuellerSchraubenTyp', 'schraube2');
          } else {
            x3d.set('aktuellerSchraubenTyp', 'schraube1');
          }

          gebaeudemasse.set('traufkonstruktion', false);
        }

        daemmung.set('SelectedVarId', daemmungData.get('SelectedVarId'));

        x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
        x3d.set('transformHelper', !x3d.get('transformHelper'));
        x3d.set('farbeGeandert', !x3d.get('farbeGeandert'));
      }
    }).observes('unterkonstruktion', 'sparrenmaterial', 'spfkl'),

    wennBauderBetonUnterkonstruktion: function wennBauderBetonUnterkonstruktion() {

      var self = this;

      if (self.get('istBetonUnterkonstruktion')) {
        this.set('spAbstand', "70.0");
        this.setX3D(70.0, { target: { name: "spAbstand" } });

        this.set('spHoehe', "14.0");
        this.setX3D(14.0, { target: { name: "spHoehe" } });

        this.set('spBreite', "10.0");
        this.setX3D(10.0, { target: { name: "spBreite" } });
      }
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {
          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('unterkonstruktionInvalid', false);

            if (self.get('unterkonstruktion') === 1 && parseFloat(self.get('schalung')) === 0) {
              application.set('unterkonstruktionInvalid', true);
            }

            self.controllerFor('supercontroller').resetApplication();

            var unterkonstruktiondata = self.controllerFor('application').get('model').unterkonstruktion.objectAt(0);

            unterkonstruktiondata.set('tUK1', parseFloat(self.get('schalung')).toFixed(1));
            unterkonstruktiondata.set('e_Sp', parseFloat(self.get('spAbstand')).toFixed(1));
            unterkonstruktiondata.set('TimberElementSp_b', parseFloat(self.get('spBreite')).toFixed(1));
            unterkonstruktiondata.set('TimberElementSp_h', parseFloat(self.get('spHoehe')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('unterkonstruktionInvalid', true);
          }
        });
      },

      schalungIsSelected: function schalungIsSelected(name, value) {
        var schalungshoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('schalungshoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('schalungshoeheAktiv', !schalungshoeheAktiv);
        if (this.get('schalung') !== "") {
          var schalung = parseFloat(this.get('schalung').replace(",", "."));
          this.set('schalung', schalung.toFixed(1));
          this.send('validation', schalung.toFixed(1), { target: { name: "schalung" } });
        }

        document.getElementsByName('schalung')[0].setSelectionRange(0, this.get('schalung').length);
      },

      spAbstandIsSelected: function spAbstandIsSelected(name, value) {
        var spabstandAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('spabstandAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('spabstandAktiv', !spabstandAktiv);
        if (this.get('spAbstand') !== "") {
          var spAbstand = parseFloat(this.get('spAbstand').replace(",", "."));
          this.set('spAbstand', spAbstand.toFixed(1));
          this.send('validation', spAbstand.toFixed(1), { target: { name: "spAbstand" } });
        }

        if (spabstandAktiv === false) {
          document.getElementsByName('spAbstand')[0].setSelectionRange(0, this.get('spAbstand').length);
        }
      },

      spHoeheIsSelected: function spHoeheIsSelected(name, value) {
        var sphoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('sphoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('sphoeheAktiv', !sphoeheAktiv);
        if (this.get('spHoehe') !== "") {
          var spHoehe = parseFloat(this.get('spHoehe').replace(",", "."));
          this.set('spHoehe', spHoehe.toFixed(1));
          this.send('validation', spHoehe.toFixed(1), { target: { name: "spHoehe" } });
        }

        if (sphoeheAktiv === false) {
          document.getElementsByName('spHoehe')[0].setSelectionRange(0, this.get('spHoehe').length);
        }
      },

      spBreiteIsSelected: function spBreiteIsSelected(name, value) {
        var spbreiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('spbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('spbreiteAktiv', !spbreiteAktiv);
        if (this.get('spBreite') !== "") {
          var spBreite = parseFloat(this.get('spBreite').replace(",", "."));
          this.set('spBreite', spBreite.toFixed(1));
          this.send('validation', spBreite.toFixed(1), { target: { name: "spBreite" } });
        }

        if (spbreiteAktiv === false) {
          document.getElementsByName('spBreite')[0].setSelectionRange(0, this.get('spBreite').length);
        }
      }
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var self = this;

      switch (event.target.name) {
        case 'schalung':
          if (this.openFromFile || this.errors.schalung.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).set('y', value);
            x3d.set('schalungshoeheEingetragen', true);
            this.setHoeheUnterkonstruktion();
            this.setSchraubenEigenschaften();
          } else {
            x3d.set('schalungshoeheEingetragen', false);
          }
          break;

        case 'spAbstand':
          if (this.openFromFile || this.errors.spAbstand.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('sparrenabstand', value);
            bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).set('z', Number(value) + 30);
            bauteile.findBy('id', 'daemmung2').get('boxsizes').objectAt(0).set('z', Number(value) + 30);
            bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).set('z', Number(value) + 30);
            x3d.set('spabstandEingetragen', true);
          } else {
            x3d.set('spabstandEingetragen', false);
          }
          break;

        case 'spHoehe':
          if (this.openFromFile || this.errors.spHoehe.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).set('y', value);
            bauteile.findBy('id', 'sparren2').get('boxsizes').objectAt(0).set('y', value);
            x3d.set('sphoeheEingetragen', true);
            this.setHoeheUnterkonstruktion();
            this.setSchraubenEigenschaften();
          } else {
            x3d.set('sphoeheEingetragen', false);
          }
          break;
        case 'spBreite':
          if (this.openFromFile || this.errors.spBreite.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).set('z', value);
            bauteile.findBy('id', 'sparren2').get('boxsizes').objectAt(0).set('z', value);
            x3d.set('spbreiteEingetragen', true);
          } else {
            x3d.set('spbreiteEingetragen', false);
          }
          break;
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    setHoeheUnterkonstruktion: function setHoeheUnterkonstruktion() {
      var sparrenhoehe = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y');
      var grundlattenhoehe = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y');
      var schalungsdicke = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y');
      var daemmungsdicke = Number(this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('y')) + Number(this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'daemmung2').get('boxsizes').objectAt(0).get('y'));

      var hoeheunterkonstruktion = Number(sparrenhoehe) / 2 + Number(grundlattenhoehe) + Number(schalungsdicke) + Number(daemmungsdicke);

      if (hoeheunterkonstruktion > 53) {
        hoeheunterkonstruktion = 53;
      }

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('hoeheUnterkonstruktion', hoeheunterkonstruktion);
    },

    setSchraubenEigenschaften: function setSchraubenEigenschaften() {

      var bauteile = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile');

      var sparrenhoehe = Number(bauteile.findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y'));
      var grundlattenhoehe = Number(bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y'));
      var schalungsdicke = Number(bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y'));
      var daemmungsdicke = Number(this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('y')) + Number(this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'daemmung2').get('boxsizes').objectAt(0).get('y'));

      var h = sparrenhoehe + schalungsdicke + daemmungsdicke + grundlattenhoehe;

      var l = daemmungsdicke / 2 + Number(bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).get('y'));
      var yPos = h - l;

      bauteile.findBy('id', 'schraubestandard21').get('screwProperties').objectAt(0).set('length', l);
      bauteile.findBy('id', 'schraubestandard21').get('translations').objectAt(0).set('y', yPos);

      bauteile.findBy('id', 'schraubestandard25').get('screwProperties').objectAt(0).set('length', l);
      bauteile.findBy('id', 'schraubestandard25').get('translations').objectAt(0).set('y', yPos);

      var l_traufk = Number(bauteile.findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).get('x'));
      var alpha = Math.atan(l_traufk / (daemmungsdicke + schalungsdicke + 10));

      l = Math.sqrt(Math.pow(10 + schalungsdicke + daemmungsdicke, 2) + Math.pow(l_traufk, 2));

      var headheight = Number(bauteile.findBy('id', 'schraubestandard22').get('screwProperties').objectAt(0).get('headheight'));

      bauteile.findBy('id', 'schraubestandard22').get('screwProperties').objectAt(0).set('length', l - headheight * 4);
      bauteile.findBy('id', 'schraubestandard22').get('rotations').objectAt(0).set('z', alpha);

      bauteile.findBy('id', 'schraubestandard26').get('screwProperties').objectAt(0).set('length', l - headheight * 4);
      bauteile.findBy('id', 'schraubestandard26').get('rotations').objectAt(0).set('z', alpha);
    },

    resetDaemmstoff: function resetDaemmstoff() {
      var self = this;
      var application = self.controllerFor('application');
      var daemmung = self.controllerFor('daemmung');
      var daemmungdata = application.get('model').daemmung.objectAt(0);
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var gebaeudemasse = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0);
      var artnr = daemmungdata.get('ArtNr1');

      daemmung.set('daemmstoffdicken_1', '');
      daemmung.set('daemmstoffemod_1', '');
      daemmung.set('daemmstoffs_10_1', '');
      daemmung.set('daemmstoffart_1', '');

      this.validations.spAbstand.numericality.lessThanOrEqualTo = parseInt(250);
      var str = (self.get('i18n').t('wertebereich') + " 25 - " + 250 + " [cm]").toString();
      self.set('ttSpAbstand', str);

      daemmung.set('openFromFile', true);
      daemmung.send('validation');
      daemmung.set('openFromFile', false);

      x3d.set('daemmstoffDickeEingetragen', false);
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    },

    setMaxSpAbstand: function setMaxSpAbstand(value) {
      var self = this;
      var application = self.controllerFor('application');
      var daemmung = self.controllerFor('daemmung');
      var daemmungdata = application.get('model').daemmung.objectAt(0);
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var gebaeudemasse = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0);
      var artnr = daemmungdata.get('ArtNr1');

      this.validations.spAbstand.numericality.lessThanOrEqualTo = parseInt(value);

      var str = (self.get('i18n').t('wertebereich') + " 25 - " + value + " [cm]").toString();

      self.set('ttSpAbstand', str);

      self.set('openFromFile', true);
      self.send('validation');
      self.set('openFromFile', false);
    }
  });

});