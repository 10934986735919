define('m01new/components/cylinde-r', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Cylinder = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'cylinde-r',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: [''],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      var x = item.get('translations').objectAt(0).get('x') / 10;
      // var y = item.get('translations').objectAt(0).get('y')/10;
      var z = item.get('translations').objectAt(0).get('z') / 10;

      var sparrenabstand = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand');
      var betondicke = x3d.get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y');
      var schubHolzDicke = 0.6;
      var kopfHoehe = item.get('cylinders').objectAt(0).get('h1') / 10;

      var y = Number(betondicke) / 10 + Number(schubHolzDicke) - Number(kopfHoehe) / 2;

      var values = x + ' ' + y + ' ' + z * sparrenabstand / 2;
      return values;
    }).property(''),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);

      return '0 0 0 0';
    }).property(''),

    cylinderTagID: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);

      return '';
    }).property(''),

    cylinderRadiusKopf: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      var value = item.get('cylinders').objectAt(0).get('d1') / 20;

      return value;
    }).property(''),

    cylinderHeightKopf: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      var value = item.get('cylinders').objectAt(0).get('h1') / 10;

      return value;
    }).property(''),

    cylinderRadiusSchaft: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      var value = item.get('cylinders').objectAt(0).get('d2') / 20;

      return value;
    }).property(''),

    cylinderHeightSchaft: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      var value = item.get('cylinders').objectAt(0).get('h2') / 10;

      return value;
    }).property(''),

    schaftTranslation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      var values = 0 + ' ' + -(item.get('cylinders').objectAt(0).get('h2') / 20 + item.get('cylinders').objectAt(0).get('h1') / 20) + ' ' + 0;

      return values;
    }).property(''),

    emissivecolor: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);

      var item = x3d.get('bauteile').findBy('id', name);

      var r = item.get('diffusecolors').objectAt(0).get('rot');
      var g = item.get('diffusecolors').objectAt(0).get('gruen');
      var b = item.get('diffusecolors').objectAt(0).get('blau');

      var emsvcolor = r + ' ' + g + ' ' + b;

      return emsvcolor;
    }).property('')
  });

  exports['default'] = Cylinder;

});