define('m01new/models/config', ['exports', 'ember-data'], function (exports, DS) {

    'use strict';

    var lower = 0;
    var upper = 100000000;
    var calcId = Math.floor(Math.random() * (upper - lower)) + lower;

    var config = DS['default'].Model.extend({

        lastannahmenEC1ServiceURL: DS['default'].attr('string')

    });

    config.reopenClass({
        FIXTURES: [{
            id: 0,
            lastannahmenEC1ServiceURL: null
        }]
    });

    exports['default'] = config;

});