define('m01new/models/x3ddefault', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var X3D = DS['default'].Model.extend({
    modul: DS['default'].attr(''),

    bauder: DS['default'].attr('boolean', { defaultValue: false }),

    transformHelper: DS['default'].attr(''),
    viewpointHelper: DS['default'].attr('boolean', { defaultValue: false }),
    dachgeometrieGeandert: DS['default'].attr('boolean', { defaultValue: false }),
    farbeGeandert: DS['default'].attr('boolean', { defaultValue: false }),

    schraubenlisteAktualisiert: DS['default'].attr('boolean', { defaultValue: false }),
    aktuellerSchraubenTyp: DS['default'].attr('string', { defaultValue: 'schraube2' }),

    bauteile: DS['default'].hasMany('bauteil', { async: false }),

    resetFieldOfView: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkel: DS['default'].attr('number', { defaultValue: 90 }),
    vWinkel: DS['default'].attr('number', { defaultValue: 30 }),
    bemessungslast: DS['default'].attr('number', { defaultValue: 0 }),

    lastViewpoint: DS['default'].attr('string', { defaultValue: 'viewpointDefault' }),

    firsthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    firsthoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    dneigungAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    dneigungEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    dneigung2Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    dneigung2Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    gbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    gbreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    glaengeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    glaengeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    uegiebelAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    uegiebelEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    uetraufeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    uetraufeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    uefirstAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    uefirstEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    schalungshoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    schalungshoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    sphoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    sphoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    spbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    spbreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    spabstandAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    spabstandEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    daemmstoffDickeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    daemmstoffDickeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    glhoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    glhoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    glbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    glbreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    vWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),

    istFassade: DS['default'].attr('boolean', { defaultValue: false }),
    istDachkonstruktion: DS['default'].attr('boolean', { defaultValue: true }),

    skalierungsfaktor: DS['default'].attr('number', { defaultValue: function defaultValue() {
        return 0.75;
      } }),
    startDistance: DS['default'].attr('number', { defaultValue: 20.4 }),
    aktuelleDistance: DS['default'].attr('number', { defaultValue: 20.4 }),
    textRefresh: DS['default'].attr('number', { defaultValue: 0 }),

    abstaendeAnzeigen: DS['default'].attr('boolean', { defaultValue: true }),
    ergebnisGeladen: DS['default'].attr('boolean', { defaultValue: false }),
    istGitterModell: DS['default'].attr('boolean', { defaultValue: false })

  });

  exports['default'] = X3D;

});