define('m01new/models/cylinder', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    h1: DS['default'].attr('number'),
    d1: DS['default'].attr('number'),
    h2: DS['default'].attr('number'),
    d2: DS['default'].attr('number')
  });

});